import React, { useState, useEffect, useRef } from "react";

function VideoPlayer({ src, client, isVertical }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
	const isMobile = window.innerWidth < 768;

    useEffect(() => {
		const videoElement = videoRef.current;

		const handlePlay = () => {
			console.log("Video is playing");
			setIsPlaying(true);
		};

		const handlePause = () => {
			console.log("Video is paused");
			setIsPlaying(false);
		};
        const handleUserInteraction = () => {
            if (videoElement) {
                videoElement.play();
            }
        };

		if (videoElement) {
			videoElement.addEventListener("play", handlePlay);
			videoElement.addEventListener("pause", handlePause);
		}

		return () => {
			if (videoElement) {
				videoElement.removeEventListener("play", handlePlay);
				videoElement.removeEventListener("pause", handlePause);

			}
		};
	}, []);


    return (
        <div className="w-full cursor-pointer">
            <div
                className={`bg-nero w-full relative ${
                    isVertical ? "aspect-[9/16]" : "aspect-[16/9]"
                } text-bianco text-center`}
            >
                {!isPlaying && (
                    <div onClick={()=>videoRef.current.play()} className="w-full h-full bg-nero/30 absolute">
                        <h2 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-bianco uppercase text-2xl font-bold z-10">
                            {client}
                        </h2>
                    </div>
                )}
                <video
                    muted
                    playsInline 
                    webkit-playsinline
                    ref={videoRef}
                    className="h-full w-full  z-0"
                    preload={"auto"}
                    controls
                    {...(isMobile ? { autoPlay: true } : {})}
                    
                >
                    <source src={src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default VideoPlayer;
