// src/Pages/Store/Cart.jsx
import React, { useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import { Link } from 'react-router-dom';
import { FaRegTrashAlt } from 'react-icons/fa';

function Cart() {
  const context = useContext(CartContext);

  if (!context) {
    // Handle the case where CartContext is not available
    return <div>Error: Cart context is not available</div>;
  }

  const { cartItems, removeFromCart } = context;

  return (
    <div className="fixed top-20 right-16 bg-bianco text-nero shadow-lg rounded-lg p-4 w-80 z-[999]">
      <h2 className="text-xl mb-4">Il tuo carrello</h2>
      {cartItems.length > 0 ? (
        <>
          <ul>
            {cartItems.map((item, index) => (
              <li key={index} className="mb-2 flex justify-between items-center">
                <img
                  src={item.image1}
                  alt={item.title}
                  className="w-12 h-12 object-cover mr-2"
                />
                <div className="flex-1">
                  <h3 className="text-sm">{item.title}</h3>
                  <p className="text-sm">€{item.price.toFixed(2)}</p>
                </div>
                <button
                  onClick={() => removeFromCart(index)}
                  className="bg-red-500 text-white p-1 rounded"
                >
                  <FaRegTrashAlt />
                </button>
              </li>
            ))}
          </ul>
          <a href="/checkout" className="block mt-4 bg-green-500 text-white p-2 rounded text-center">
            Procedi al pagamento
          </a>
        </>
      ) : (
        <p>Il tuo carrello è vuoto.</p>
      )}
    </div>
  );
}

export default Cart;
