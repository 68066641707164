import React, { useState } from "react";
import gsap from "gsap";
import ReactPageScroller, { SectionContainer } from "react-page-scroller";
import Contattaci from "../../Components/Molecules/Contattaci/Contattaci";
import images1 from "../../assets/perchi/LOGHI COLORATI/images1";
import images2 from "../../assets/perchi/LOGHI COLORATI/images2";
import images3 from "../../assets/perchi/LOGHI COLORATI/images3";
import { motion } from "framer-motion";
import Footer from "../../Components/Molecules/Footer/Footer";

function Perchi() {
    var lastPage = 0;
    const isMobile = window.innerWidth < 1025;

    const handlePageChange = (number) => {
        if (number === 0 && lastPage === 1) {
            gsap.fromTo(
                "#perchi",
                { opacity: 0, scale: 0 },
                { opacity: 1, scale: 1, duration: 1 }
            );
        } else if (number === 1 && lastPage === 0) {
            gsap.fromTo(
                "#perchi",
                { opacity: 1, scale: 1 },
                { opacity: 0, scale: 0, duration: 1 }
            );
        } else if (number === 3 && lastPage === 2) {
            gsap.fromTo(
                "#contatti_perchi",
                {
                    height: "100vh",
                },
                {
                    height: "80vh",
                    duration: 1,
                }
            );
            gsap.fromTo(
                "#footer_perchi",
                { translateY: "100%" },
                { translateY: 0, duration: 1 }
            );
        } else if (number === 2 && lastPage === 3) {
            gsap.fromTo(
                "#contatti_perchi",
                {
                    height: "80vh",
                },
                {
                    height: "100vh",
                    duration: 1,
                }
            );
            gsap.fromTo(
                "#footer_perchi",
                { translateY: 0 },
                { translateY: "100%", duration: 1 }
            );
        }

        lastPage = number;
        console.log(number);
    };

    return (
        <div className="">
            {!isMobile && <div
                id="perchi"
                className="fixed md:block hidden uppercase  text-bianco top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 text-center"
            >
                <h1 className="md:text-8xl text-6xl font-bold">
                    {" "}
                    <nobr>Per chi</nobr>{" "}
                </h1>
                <h1 className="text-4xl font-semibold mt-5">
                    abbiamo lavorato?
                </h1>
            </div>}
            {isMobile ? (
                <div className="">
                  <div
                id="perchi"
                className="   uppercase  text-bianco w-full h-[100lvh] flex justify-center items-center text-center"
            >
                <div className="px-10">
                <h1 className="md:text-8xl text-6xl font-bold">
                    {" "}
                    <nobr>Per chi</nobr>{" "}
                </h1>
                <h1 className="text-4xl font-semibold mt-5">
                    abbiamo lavorato?
                </h1>
                </div>
            </div>
                    <div className="flex flex-col mb-44 justify-center">
                        <div className="flex items-center mb-5">
                            <TestimonialList list={images1}  />
                            <TestimonialList list={images1}  />
                            <TestimonialList list={images1}  />
                        </div>
                        <div className="flex items-center mb-5">
                            <TestimonialList list={images2}  reverse />
                            <TestimonialList list={images2}  reverse />
                            <TestimonialList list={images2}  reverse />
                        </div>
                        <div className="flex items-center mb-5">
                            <TestimonialList list={images3}  />
                            <TestimonialList list={images3}  />
                            <TestimonialList list={images3}  />
                        </div>
                    </div>
                    <div className=" mb-44 mt-20 relative">
                        <div
                            id=""
                            className="section  w-full flex justify-center items-center relative"
                        >
                            <Contattaci />{" "}
                        </div>
                    </div>
                        <div
                            id=""
                            className="  w-full "
                        >
                            <Footer />
                        </div>
                </div>
            ) : (
                <ReactPageScroller
                    animationTimer={800}
                    animationTimerBuffer={300}
                    renderAllPagesOnFirstRender={true}
                    transitionTimingFunction={"ease-in-out"}
                    onBeforePageScroll={handlePageChange}
                >
                    <div className="relative h-[100lvh]" />

                    <div className="flex flex-col h-[100lvh] justify-center">
                        <div className="flex items-center mb-5">
                            <TestimonialList list={images1}  />
                            <TestimonialList list={images1}  />
                            <TestimonialList list={images1}  />
                        </div>
                        <div className="flex items-center mb-5">
                            <TestimonialList list={images2}  reverse />
                            <TestimonialList list={images2}  reverse />
                            <TestimonialList list={images2}  reverse />
                        </div>
                        <div className="flex items-center mb-5">
                            <TestimonialList list={images3}  />
                            <TestimonialList list={images3}  />
                            <TestimonialList list={images3}  />
                        </div>
                    </div>

                    <SectionContainer height={1}></SectionContainer>
                    <div className="h-[100lvh] relative">
                        <div
                            id="contatti_perchi"
                            className="section w-full h-[100%] flex justify-center items-center relative"
                        >
                            <Contattaci />{" "}
                        </div>
                        <div
                            id="footer_perchi"
                            className="absolute  w-full translate-y-[100%] bottom-0"
                        >
                            <Footer />
                        </div>
                    </div>
                </ReactPageScroller>
            )}
        </div>
    );
}

export default Perchi;

const TestimonialList = ({ list, reverse = false, duration = 50 }) => {
    return (
      <motion.div
        initial={{ translateX: reverse ? "-100%" : "0%" }}
        animate={{ translateX: reverse ? "0%" : "-100%" }}
        transition={{ duration, repeat: Infinity, ease: "linear" }}
        className="flex gap-4 px-2"
      >
        {list.map((t,index) => {
          return (
            <div
              key={index}
              className="shrink-0 mx-10 grid grid-cols-[7rem,_1fr] rounded-lg overflow-hidden relative"
            >
              <img src={t} alt="" className="w-full h-44 object-contain" />
              <span className="text-7xl absolute top-2 right-2 text-slate-700">
                
              </span>
            </div>
          );
        })}
      </motion.div>
    );
  };
  

const TranslateWrapper = ({ children, reverse }) => {
    return (
        <motion.div
            initial={{ translateX: reverse ? "-100%" : "100%" }}
            animate={{ translateX: reverse ? "100%" : "-100%" }}
            transition={{
                duration: 25,
                repeat: Infinity,
                ease: "linear",
                repeatType: "reverse",
            }}
            className="flex gap-4 px-2"
        >
            {children}
        </motion.div>
    );
};
