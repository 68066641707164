import React, { useState } from "react";
import Chat from "../../../assets/SVG/Chat";
import Film from "../../../assets/SVG/Film";


function Contattaci() {
    const [hover, setHover] = useState(0);
    return (
        <div className="flex flex-col justify-center items-center w-full ">
           
            <h1 className="md:text-5xl text-4xl  uppercase text-center text-bianco  font-extrabold">
                dove possiamo contattarti
            </h1>
            <div className="flex md:flex-row flex-col md:space-y-0 space-y-5 space-x-1 text-bianco md:text-3xl text-2xl mt-5">
                <h2 className="font-bold">Cosa stai cercando? </h2>
                <h3 className="font-normal pl-3 md:block hidden">
                    Scegli un'opzione
                </h3>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 w-screen gap-5 justify-items-center items-center md:mt-40 mt-5 px-20">
                <div
                    onClick={() => (window.location.href = "/contactus")}
                    className="flex flex-col h-52 w-full items-center  md:-translate-y-0 cursor-pointer relative"
                >
                    <Chat hover={hover} setHover={setHover} />
                </div>
                <div className="font-extrabold text-bianco md:text-9xl text-6xl">
                    ?
                </div>
                <div
                    onClick={() => (window.location.href = "/joinus")}
                    className="flex h-52 w-full items-center  md:-translate-y-0 flex-col relative cursor-pointer"
                >
                    <Film hover={hover} setHover={setHover} />
                </div>
            </div>
        </div>
    );
}

export default Contattaci;
