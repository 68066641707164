import React from "react";
import Chimera from "../../../assets/SVG/Chimera.jsx";
import Insta from "../../../assets/SVG/Insta.jsx";
import TikTok from "../../../assets/SVG/TikTok.jsx";
import Newsletter from "../../Atoms/Input/Newsletter.jsx";

function Footer() {
    return (
        <div className="z-30 w-[100vw] relative bottom-0 lg:h-[28vh] h-[95vh] bg-nero flex flex-col justify-between ">
            <div className="text-bianco mt-10 flex flex-col lg:flex-row space-y-16 justify-around md:space-y-0 md:justify-center items-center">
                <div className="md:w-96 flex justify-center items-center">
                    <Chimera w="200" />
                </div>
                <div
                    id="contatti"
                    className="flex flex-col space-y-2 justify-center items-center md:w-96 md:mx-32"
                >
                    <p className="text-2xl font-semibold uppercase">
                        CONTATTI{" "}
                    </p>
                    <a
                        href="tel:+393517649360"
                        className="font-thin hover:underline"
                    >
                        +39 351 764 9360
                    </a>
                    <a
                        href="mailto:jeck@chimerastudioss.com"
                        className="font-thin hover:underline"
                    >
                        jeck@chimerastudioss.com
                    </a>
                    <a
                        href="https://maps.app.goo.gl/JAEpoH5AMwV5HLM66"
                        className="font-thin hover:underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        via Antonello da Messina 5, MI
                    </a>
                    <div className="flex gap-3 font-thin">
                        <a className="underline" href="/privacy-policy">Privacy Policy</a>
                        <p>-</p>
                        <a className="underline" href="/condizioni">Condizioni d'uso</a>
                    </div>
                </div>
                <div
                    id="family"
                    className="flex flex-col space-y-5 justify-center items-center md:w-96"
                >
                    {" "}
                    <p className="text-2xl font-semibold uppercase">
                        join the family
                    </p>
                    <div className="flex lg:flex-row flex-col items-center gap-x-5 pb-3 justify-evenly">
                        <a
                            className="md:hidden flex items-center justify-center gap-x-2"
                            href="instagram://user?username={chimera.studioss}"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Insta />
                            <span className="hover:underline">
                                @chimera.studioss
                            </span>
                        </a>
                        <a
                            className="hidden font-thin md:flex  items-center justify-center gap-x-2"
                            href="https://www.instagram.com/chimera.studioss/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Insta />
                            <span className="hover:underline">
                                @chimera.studioss
                            </span>
                        </a>
                        <a
                            className="md:hidden flex items-center justify-center gap-x-2"
                            href="tiktok://user?={@chimerafamily}"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TikTok />
                            <span className="hover:underline">
                                chimerafamily{" "}
                            </span>
                        </a>
                        <a
                            className="hidden  font-thin md:flex  items-center justify-center gap-x-2"
                            href="https://www.tiktok.com/@chimerafamily"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TikTok />
                            <span className="hover:underline">
                                chimerafamily{" "}
                            </span>
                        </a>
                    </div>
                    <Newsletter />
                </div>
            </div>
            <p className="text-bianco text-sm text-center mb-6 font-thin  w-full">
                p.iva/cf: 12743600962 - <br className="md:hidden" /> copyright © 2024 Chimera Studio S.R.L.
            </p>
        </div>
    );
}

export default Footer;
