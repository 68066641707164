import React from "react";

function Carrello({ className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 34 30"
    >
      <path
        fill="#fff"
        d="M0 .695C.247.192.641-.005 1.206 0c1.846.02 3.692.007 5.538.008.78 0 1.101.25 1.278 1.014.196.85.394 1.7.558 2.557.063.33.204.416.529.415 7.815-.008 15.63.01 23.446-.024 1.239-.006 1.602.702 1.302 1.726-1.294 4.41-2.538 8.833-3.8 13.252-.222.781-.513 1.005-1.328 1.005H11.32c-.211 0-.42.002-.625.059a.968.968 0 00-.705.903c-.008.422.299.814.717.926.207.055.415.056.626.056h17.51c.72 0 1.165.395 1.161 1.025a.99.99 0 01-.976 1.006c-.427.013-.853.003-1.342.003 2.06.675 2.521 2.414 2.26 3.573-.34 1.514-1.58 2.449-3.139 2.393-1.356-.048-2.59-1.16-2.79-2.54-.205-1.418.563-2.9 2.288-3.407H13.686c2.027.67 2.452 2.428 2.199 3.553-.343 1.524-1.595 2.461-3.171 2.393-1.336-.057-2.56-1.17-2.759-2.536-.207-1.427.57-2.906 2.229-3.427-.398 0-.796.003-1.194 0-1.47-.012-2.6-.893-2.976-2.315-.335-1.264.26-2.621 1.48-3.321.232-.133.193-.285.152-.469l-1.14-5.109c-.764-3.44-1.529-6.878-2.285-10.318-.057-.258-.153-.365-.436-.363-1.514.014-3.029-.009-4.543.015-.574.01-.987-.175-1.24-.695V.695H0zm20.382 5.33c-3.613 0-7.227 0-10.84-.004-.272 0-.41.028-.334.368.84 3.737 1.667 7.478 2.493 11.22.047.212.13.314.373.314 5.272-.007 10.542-.007 15.814 0 .248 0 .348-.095.412-.32 1.062-3.74 2.127-7.479 3.203-11.214.087-.303.037-.371-.28-.37-3.613.01-7.227.007-10.84.007v-.001zm-7.449 19.914a.974.974 0 00-.954.948.975.975 0 00.94.963c.518.013.978-.444.97-.964a.975.975 0 00-.955-.947zm15.018.963a.975.975 0 00-.94-.963c-.505-.012-.953.417-.971.932-.019.516.434.98.956.98.51 0 .95-.436.955-.948z"
      ></path>
    </svg>
  );
}

export default Carrello;