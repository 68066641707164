import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { CiCircleAlert } from "react-icons/ci";
import { AddToNewsletter } from "../../../utils/Newsletter";

const ContactModal = ({ isOpen, setIsOpen, back, setBack, done, setDone, fail, setFail }) => {
	const [formData, setFormData] = useState({
		project: "",
		description: "",
		role: "",
		name: "",
		agency: "",
		email: "",
		phone: "",
		privacy: false,
		newsletter: false,
		type: "clienti",
	});
	const [errors, setErrors] = useState({}); // Stato per gli errori

	// Funzione per gestire il cambiamento dei campi del form
	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
		setErrors({ ...errors, [name]: "" }); // Resetta l'errore per il campo corrente
	};

	const validateForm = () => {
		const newErrors = {};

		// Verifica i campi obbligatori
		if (!formData.project) newErrors.project = "Il progetto è obbligatorio.";
		if (!formData.description)
			newErrors.description = "La descrizione è obbligatoria.";
		if (!formData.role) newErrors.role = "La figura cercata è obbligatoria.";
		if (!formData.name) newErrors.name = "Il nome è obbligatorio.";
		if (!formData.agency)
			newErrors.agency = "Il nome dell'agenzia è obbligatorio.";
		if (!formData.email) newErrors.email = "L'indirizzo email è obbligatorio.";
		if (!formData.phone)
			newErrors.phone = "Il numero di cellulare è obbligatorio.";
		if (!formData.privacy)
			newErrors.privacy = "È necessario accettare la privacy policy.";

		setErrors(newErrors);

		// Add error class to inputs with errors
		Object.keys(newErrors).forEach((key) => {
			const inputElement = document.querySelector(`[name="${key}"]`);
			if (inputElement) {
				inputElement.classList.add("error");
			}
		});

		// Remove error class from inputs without errors
		Object.keys(formData).forEach((key) => {
			if (!newErrors[key]) {
				const inputElement = document.querySelector(`[name="${key}"]`);
				if (inputElement) {
					inputElement.classList.remove("error");
				}
			}
		});

		return Object.keys(newErrors).length === 0; // Ritorna true se non ci sono errori
	};

	// Funzione per inviare i dati al server
	const handleSubmit = async () => {
		if (!validateForm()) {
			return;
		}
		if (formData.newsletter) {
            try {
                AddToNewsletter(formData.email);
            } catch (error) {
                console.log(
                    "Errore durante l'iscrizione alla newsletter:",
                    error
                );
            }
        }
		try {

			const headers = {
				"Content-Type": "application/json",
			};
			await axios.post(
				 process.env.REACT_APP_API_URL + '/sendmail',
				{data:formData, type:"clienti"}, // Invia i dati correttamente formattati come JSON
				{ headers }
			).then((res) => {
				console.log(res.data); // Debug della risposta
				if (res.data.success) {
					setDone(true); // Mostra il messaggio di invio riuscito
				} else {
					setDone(true); // Chiudi il form
					setFail(true); // Mostra il messaggio di invio fallito
				}
				
			}).catch((err) => {
				console.error(err); // Debug dell'errore
			});
		} catch (error) {
			console.error("Errore durante l'invio dei dati:", error);
			alert("Si è verificato un errore durante l'invio dei dati. Riprova più tardi.");
		}
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					onClick={() => setIsOpen(false)}
					className=" p-8  w-[100vw] h-full flex justify-center items-center   "
				>
					{!done ? (
						<motion.div
							initial={{ opacity: 0, x: 0, y: "100%" }}
							animate={
								back
									? { opacity: 1, y: 0 }
									: { scale: 0.8, opacity: 0.5, x: "-50%", y: "-2%" }
							}
							transition={{ duration: 0.6, ease: [0.54, 0.44, 0.43, 0.81] }}
							exit={{ opacity: 0 }}
							onClick={(e) => e.stopPropagation()}
							className={`md:top-1/8 top-[15%] -translate-x-1/2 mb-12 bg-azzurro text-white p-6 rounded-lg w-full md:max-w-md max-w-[90%] shadow-xl cursor-default absolute overflow-hidden flex flex-col items-center justify-center gap-4`}
						>
							<h3 className="text-bianco font-semibold text-4xl">
								Raccontaci il tuo progetto
							</h3>
							<div className="w-full flex flex-col">
								<label
									className={`text-xs text-[#ff0000] place-self-end ${
										!errors.project && "hidden"
									}`}
								>
									{errors.project}
								</label>
								<input
									value={formData.project}
									onChange={handleChange}
									name="project"
									className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.project ? "error" : ""
									}`}
									type="text"
									placeholder="Il tuo progetto"
								/>
							</div>
							<div className="w-full flex flex-col">
								<label
									className={`text-xs text-[#ff0000] place-self-end ${
										!errors.description && "hidden"
									}`}
								>
									{errors.description}
								</label>
								<textarea
									value={formData.description}
									onChange={handleChange}
									name="description"
									className={`w-full h-52 rounded-xl text-xl px-4 py-2 bg-bianco  focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.description ? "error" : ""
									}`}
									placeholder="Descrivici il tuo progetto"
								></textarea>
							</div>
							<label className="text-lg text-bianco place-self-start">
								Quale figura stai cercando?
							</label>

							<div className="w-full flex flex-col">
								<label
									className={`text-xs text-[#ff0000] place-self-end ${
										!errors.role && "hidden"
									}`}
								>
									{errors.role}
								</label>
								<input
									value={formData.role}
									onChange={handleChange}
									name="role"
									className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.role ? "error" : ""
									}`}
									type="text"
									placeholder="Es. fotografo"
								/>
							</div>
							<button
								className="uppercase text-bianco cursor-pointer"
								onClick={() => {
									setIsOpen(false);
									window.history.back();
								}}
							>
								indietro
							</button>
							<button
								onClick={() => setBack(false)}
								className="uppercase rounded-full md:w-[30%] text-xl py-1 px-2 hover:w-[35%] transition-all duration-300 text-azzurro font-bold bg-bianco"
							>
								prosegui
							</button>
						</motion.div>
					) : (
						""
					)}
					{!back && !done ? (
						<motion.div
							initial={{ opacity: 0, x: "100%" }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.6, ease: [0.54, 0.44, 0.43, 0.81] }}
							exit={{ opacity: 0, x: "100%" }}
							onClick={(e) => e.stopPropagation()}
							className="md:top-1/8 top-[15%] bg-azzurro text-white p-6 rounded-lg w-full md:max-w-md max-w-[90%] shadow-xl cursor-default absolute overflow-hidden flex flex-col items-center justify-center gap-4"
						>
							<h3 className="text-bianco font-semibold md:text-4xl text-3xl justify-self-start">
								Parlaci un po' di te
							</h3>
							<div className="w-full">
								<div className="w-full flex justify-between">
									<label className="text-xl text-bianco ">Nome e cognome</label>
									<label
										className={`text-xs text-[#ff0000] place-self-end  ${
											!errors.name && "hidden"
										}`}
									>
										{errors.name}
									</label>
								</div>
								<input
									value={formData.name}
									onChange={handleChange}
									name="name"
									className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.name ? "error" : ""
									}`}
									type="text"
									placeholder="Es. Mario Rossi"
								/>
							</div>
							<div className="w-full">
								<div className="w-full flex justify-between">
									<label className="text-xl text-bianco ">Nome agenzia</label>
									<label
										className={`text-xs text-[#ff0000] place-self-end  ${
											!errors.agency && "hidden"
										}`}
									>
										{errors.agency}
									</label>
								</div>
								<input
									value={formData.agency}
									onChange={handleChange}
									name="agency"
									className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.agency ? "error" : ""
									}`}
									type="text"
									placeholder="Es. La mia agenzia srl"
								/>
							</div>
							<div className="w-full">
								<div className="w-full flex justify-between">
									<label className="text-xl text-bianco ">
										Indirizzo email
									</label>
									<label
										className={`text-xs text-[#ff0000] place-self-end  ${
											!errors.email && "hidden"
										}`}
									>
										{errors.email}
									</label>
								</div>
								<input
									value={formData.email}
									onChange={handleChange}
									name="email"
									className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.email ? "error" : ""
									}`}
									type="text"
									placeholder="Es. info@mariorossi.com"
								/>
							</div>
							<div className="w-full">
								<div className="w-full flex justify-between">
									<label className="text-xl text-bianco ">
										Numero di cellulare
									</label>
									<label
										className={`text-xs text-[#ff0000] place-self-end  ${
											!errors.phone && "hidden"
										}`}
									>
										{errors.phone}
									</label>
								</div>
								<input
									value={formData.phone}
									onChange={handleChange}
									name="phone"
									className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
										errors.phone ? "error" : ""
									}`}
									type="text"
									placeholder="Es. +39 123 456 7890"
								/>
							</div>

							<div className="w-full flex flex-auto items-center gap-2">
								<input
									value={formData.privacy}
									onChange={handleChange}
									name="privacy"
									type="checkbox"
									className={`appearence-none w-5 aspect-square accent-bianco`}
								/>
								<div className="flex flex-col">
									<label className="text-bianco text-xs">
										Ho letto e accettato la{" "}
										<a className="cursor-pointer underline" href="/privacy-policy">
											privacy policy
										</a>{" "}
										e i{" "}
										<a className="cursor-pointer underline" href="/condizioni">
											termini e condizioni
										</a>
									</label>
									<label
										className={`text-xs text-[#ff0000] ${
											!errors.privacy && "hidden"
										}`}
									>
										{errors.privacy}
									</label>
								</div>
							</div>
							<div className="w-full flex flex-auto items-center gap-2">
								<input
									value={formData.newsletter}
									onChange={handleChange}
									name="newsletter"
									type="checkbox"
									className="appearence-none w-5 aspect-square accent-bianco"
								/>
								<label className="text-bianco text-xs">
									Vorrei iscrivermi alla vostra{" "}
									<span className="font-semibold">Newsletter</span>
								</label>
							</div>

							<button
								className="uppercase text-bianco cursor-pointer"
								onClick={() => setBack(1)}
							>
								indietro
							</button>
							<button
								type="submit"
								className="uppercase text-xl py-1 px-2 rounded-full md:w-[20%] hover:w-[25%] transition-all duration-300 text-azzurro font-bold bg-bianco"
								onClick={() => handleSubmit()}
							>
								invia
							</button>
						</motion.div>
					) : (
						""
					)}
					{done && !fail ? (
						<motion.div
							initial={{ opacity: 0, x: 0, y: "100%" }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.6, ease: [0.54, 0.44, 0.43, 0.81] }}
							exit={{ opacity: 0 }}
							onClick={(e) => e.stopPropagation()}
							className={` text-white p-6 rounded-lg w-full max-w-md absolute overflow-hidden flex flex-col items-center justify-center gap-7`}
						>
							<h3 className="text-bianco text-4xl text-center">
								Inviato con successo
							</h3>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="187"
								height="187"
								fill="none"
								viewBox="0 0 234 234"
							>
								<circle
									cx="117.123"
									cy="117.242"
									r="109.559"
									stroke="#D9D9D9"
									strokeWidth="14.024"
								></circle>
								<path
									stroke="#D9D9D9"
									strokeLinecap="round"
									strokeWidth="14.024"
									d="M65.412 111.107l39.027 35.058 64.396-57.847"
								></path>
							</svg>
							<div>
								<p className="text-bianco text-center text-lg">
									Grazie per averci raccontato di te.
								</p>
								<p className="text-bianco text-center text-lg">A presto!</p>
							</div>
							<button
								className="uppercase text-bianco cursor-pointer hidden"
								onClick={() => setIsOpen(false) || setDone(0)}
							>
								chiudi
							</button>
						</motion.div>
					) : (
						""
					)}{fail ? (
						<motion.div
							initial={{ opacity: 0, x: 0, y: "100%" }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.6, ease: [0.54, 0.44, 0.43, 0.81] }}
							exit={{ opacity: 0 }}
							onClick={(e) => e.stopPropagation()}
							className={` text-white p-6 rounded-lg w-full max-w-xl absolute overflow-hidden flex flex-col items-center justify-center gap-7`}
						>
							<CiCircleAlert className="text-bianco w-44 h-44" />
							<h3 className="text-bianco text-4xl text-center">
								Qualcosa è andato storto
							</h3>
							<div>
								<p className="text-bianco text-center text-lg">
									Non siamo riusciti a inviare il tuo messaggio, riprova più
									tardi.
								</p>
								<p className="text-bianco text-center text-lg">
									Se il problema persiste, contattaci a{" "}
									<a
										className="hover:underline cursor-pointer"
										href="mailto:jeck@chimerastudioss.com"
									>
										jeck@chimerastudioss.com
									</a>
								</p>
							</div>
							<button
								className="uppercase text-bianco cursor-pointer hidden"
								onClick={() => setIsOpen(false) || setDone(0)}
							>
								chiudi
							</button>
						</motion.div>
					) : (
						""
					)}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default ContactModal;
