import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Molecules/Navbar/Navbar";
import Footer from "./Components/Molecules/Footer/Footer";
import "./i18n";
import Contattaci from "./Components/Molecules/Contattaci/Contattaci";
import Chisiamo from "./Pages/Chisiamo/Chisiamo";
import Servizi from "./Pages/Servizi/Servizi";
import Perchi from "./Pages/Perchi/Perchi";
import Contatti from "./Pages/Contatti/Contatti";
import Community from "./Pages/Community/Community";
import Join_us from "./Pages/Join_contact/Join_us";
import Contatc_us from "./Pages/Join_contact/Contatc_us";
import Store from "./Pages/Store/Store";
import CheckoutForm from "./Pages/Store/Checkout";
import Return from "./Pages/Store/Return";
import { CartProvider } from "./Context/CartContext";
import NFND from "./Pages/NOT FOUND/NFND";
import TeamPage from "./Pages/Chisiamo/TeamPage";
import PrivacyPolicy from "./Pages/Policy/PrivacyPolicy";
import Condizioni from "./Pages/Policy/Condizioni";

function App() {
    const url = window.location.href.split("/").pop();
    console.log(url);

    useEffect(() => {
        document.addEventListener("contextmenu", function (e) {
            e.preventDefault();
        });
    }, []);

    return (
        <div className="font-quanta overflow-x-hidden">
            <Suspense fallback={null}>
                <CartProvider>
                    <Navbar />
                    <BrowserRouter>
                        <Routes>
                            <Route element={<Home />} path="/" />
                            <Route element={<Chisiamo />} path="/chisiamo" />
                            <Route element={<TeamPage />} path="/team" />
                            <Route element={<Servizi />} path="/servizi/:id" />
                            <Route element={<Perchi />} path="/perchi" />
                            <Route element={<Contatti />} path="/contatti" />
                            <Route element={<Join_us />} path="/joinus" />
                            <Route element={<Contatc_us />} path="/contactus" />
                            <Route
                                element={<PrivacyPolicy />}
                                path="/privacy-policy"
                            />
                            <Route
                                element={<Condizioni />}
                                path="/condizioni"
                            />

                            <Route element={<Store />} path="/store" />
                            <Route
                                path="/checkout"
                                element={<CheckoutForm />}
                            />
                            <Route path="/return" element={<Return />} />
                            <Route
                                element={<Navigate to="/" replace />}
                                path="/*"
                            />
                        </Routes>{" "}
                    </BrowserRouter>{" "}
                    {url !== "joinus" &&
                        url !== "contactus" &&
                        url !== "checkout" &&
                        url !== "" &&
                        url !== "chisiamo" &&
                        url !== "perchi" && <Footer />}
                </CartProvider>
            </Suspense>
        </div>
    );
}

export default App;
