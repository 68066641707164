export const articoli = [
	{
		title: "01 - HOODIE S",
		colorway: "Black",
		id: "prod_QVEMzUZM4rbuDj",
		price: 40.0,
		description:
			"lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		image1: "https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
		images: [
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
		],
		size: "S",
	},
	{
		title: "01 - HOODIE M",
		colorway: "Black",
		price: 40.0,
		description:
			"lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		image1: "https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
		images: [
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
		],
		size: "M",
	},
	{
		title: "01 - HOODIE L",
		colorway: "Black",
		price: 40.0,
		description:
			"lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		image1: "https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
		images: [
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
			"https://chimerastudio.s3.eu-north-1.amazonaws.com/felpa3D.png",
		],
		size: "L",
	}
];
