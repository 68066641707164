import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { motion } from "framer-motion";

const Return = () => {
	const [status, setStatus] = useState(null);
	const [customerEmail, setCustomerEmail] = useState("");

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		//const sessionId = window.location.href.split("/").pop();
		const sessionId = urlParams.get("session_id");


		axios
			.get(process.env.REACT_APP_API_URL + `/session-status?session_id=${sessionId}`)
			.then((response) => {
				const data = response.data;
				setStatus(data.status);
				setCustomerEmail(data.customer_email);
			})
			.catch((error) => {
				console.error("There was an error fetching the session status!", error);
			});
	}, []);

	if (status === "open") {
		return <Navigate to="/checkout" />;
	}

	if (status === "complete") {
		return (
			<section className="h-[100vh] flex flex-col items-center justify-center gap-7">
				<motion.div
					initial={{ opacity: 0, x: 0, y: "100%" }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.6, ease: [0.54, 0.44, 0.43, 0.81] }}
					exit={{ opacity: 0 }}
					onClick={(e) => e.stopPropagation()}
					className={`text-white p-6 rounded-lg w-full max-w-md  absolute overflow-hidden flex flex-col items-center justify-center gap-7`}
				>
					<h3 className="text-bianco text-4xl text-center">Ordine inviato con successo</h3>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="187"
						height="187"
						fill="none"
						viewBox="0 0 234 234"
					>
						<circle
							cx="117.123"
							cy="117.242"
							r="109.559"
							stroke="#D9D9D9"
							strokeWidth="14.024"
						></circle>
						<path
							stroke="#D9D9D9"
							strokeLinecap="round"
							strokeWidth="14.024"
							d="M65.412 111.107l39.027 35.058 64.396-57.847"
						></path>
					</svg>
					<div>
						<p className="text-bianco text-center text-lg">
							Grazie per acquistato un nostro prodotto.
						</p>
						<p className="text-bianco text-center text-lg">A presto!</p>
					</div>
					<button
						className="uppercase text-bianco cursor-pointer hidden"
						onClick={() => window.history.back()}
					>
						chiudi
					</button>
				</motion.div>
			</section>
		);
	}

	return null;
};

export default Return;
