import React, { useState } from "react";
import ContactModal from "../../Components/Molecules/Modals/ContactModal";
import Sfumatura1 from "../../assets/sfumature/1.png";

function Contatc_us() {
    const [hover, setHover] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [back, setBack] = useState(1);
    const [doneC, setDoneC] = useState(0);
    const [fail, setFail] = useState(0);
    return (
        <div className="h-[100vh] w-full ">
            <div
                id="sfumatura1"
                className="hidden w-full fixed brightness-0 invert right-0 top-[80%] z-0 -translate-y-1/2 -translate-x-0 scale-[200%] rotate-[200deg]"
            >
                <img src={Sfumatura1} className=" opacity-15" alt="" />
            </div>
            <ContactModal
                isOpen={true}
                setIsOpen={setIsOpen}
                back={back}
                setBack={setBack}
                done={doneC}
                setDone={setDoneC}
                fail={fail}
                setFail={setFail}
            />
        </div>
    );
}

export default Contatc_us;
