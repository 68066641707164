import React, { useState } from "react";
import { motion } from "framer-motion";

function Servizio({ titolo, descr, backgroundImage, redirect }) {
	const [hover, setHover] = useState(window.innerWidth > 1024 ? false : true);
	return (
		<div
			onClick={() => (window.location.href = "/servizi/" + redirect)}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => window.innerWidth > 1024 && setHover(false)}
			style={{ backgroundImage: `url(${backgroundImage})` }}
			className={`cursor-pointer bg-azzurro rounded-lg aspect-auto lg:w-[50%] h-80 xl:h-[35vh]
				hover:scale-[104%] duration-300 transition-all bg-cover bg-center bg-no-repeat overflow-hidden`}
		>
			<div className=" p-6 h-full relative rounded-lg">
			<motion.div
                    initial={{ opacity: 0, x: "-10%", y: 0 }}
                    animate={{ opacity: 1, x: 0, y: 0 }}
                    transition={{
                        duration: 0.3,
                        ease: [0.54, 0.44, 0.43, 0.81],
                    }}
                    exit={{ opacity: 0 }}
                    className={`${"absolute"} top-0 left-0 z-0 h-full w-[100%] bg-gradient-to-b from-[#000] via-nero/0 to-nero/0`}
                ></motion.div>
				<motion.div
					initial={{ opacity: 0, x: "-10%", y: 0 }}
					animate={
						hover ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x: "-10%", y: 0 }
					}
					transition={{ duration: 0.3, ease: [0.54, 0.44, 0.43, 0.81] }}
					exit={{ opacity: 0 }}
					className={`${
						!hover ? "hidden" : "absolute"
					} top-0 left-0 z-0 h-full w-[100%] bg-gradient-to-r from-[#000]/90 via-nero/80 to-nero/20`}
				></motion.div>
				<h1 className="z-20 absolute uppercase text-xl font-bold">{titolo}</h1>
				<motion.p
					className={`${
						!hover ? "hidden" : "absolute"
					} mt-5 top-1/4 text-lg  `}
					initial={{ opacity: 0, x: "-10%", y: 0 }}
					animate={
						hover ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x: "-10%", y: 0 }
					}
					transition={{ duration: 0.3, ease: [0.54, 0.44, 0.43, 0.81] }}
					exit={{ opacity: 0 }}
				>
					{descr}
				</motion.p>
			</div>
		</div>
	);
}

export default Servizio;
