import axios from "axios";
import React, { useState } from "react";

function Newsletter() {
    const [email, setEmail] = useState("");
    const handleSubmit = async (e) => {
        const headers = {
            "Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
        };
        e.preventDefault();
        await axios
            .post(
                process.env.REACT_APP_API_URL + "/addnewsletter",
                {
                    email: email,
                },
                { headers }
            )
            .then((res) => {
                console.log(res.data);
                setEmail("");
            });
    };
    return (
        <div className="w-full flex justify-center items-center">
            <input
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                className="bg-bianco text-nero rounded-l-full w-64 px-3"
                type="text"
                placeholder="Iscriviti alla newsletter"
            />
            <button
                onClick={(e)=>handleSubmit(e)}
                className="px-3 bg-azzurro rounded-r-full"
                type="submit"
            >
                INVIA
            </button>
        </div>
    );
}

export default Newsletter;
