import React from "react";

function PrivacyPolicy() {
  return (
    <div className="text-bianco text-lg p-24 mt-10">
      <h1 className="text-3xl text-center font-bold">INFORMATIVA PRIVACY</h1>

      <h2 className="text-xl text-center my-20">1 - Informazioni relative alla Privacy del sito</h2>
      <p className="mt-5">
        <strong>1.1.</strong> In questa sezione sono contenute le informazioni relative alle modalità di
        gestione di Chimera Studios, di proprietà di Chimera Studio S.R.L., in riferimento al
        trattamento dei dati degli utenti del sito stesso.
      </p>
      <p className="mt-5">
        <strong>1.2.</strong> La presente informativa ha valore anche ai fini dell'articolo 13 del Regolamento
        (UE) n. 2016/679, relativo alla protezione delle persone fisiche con riguardo al
        trattamento dei dati personali nonché alla libera circolazione di tali dati, per i soggetti
        che interagiscono con Chimera Studios.
      </p>
      <p className="mt-5">
        <strong>1.3.</strong> L'informativa è resa solo per Chimera Studios e non anche per altri siti web
        eventualmente consultati dall'utente tramite link in esso contenuti.
      </p>
      <p className="mt-5">
        <strong>1.4.</strong> Scopo del presente documento è fornire indicazioni circa le modalità, i tempi e la
        natura delle informazioni che i titolari del trattamento devono fornire agli utenti al
        momento della connessione alle pagine web di Chimera Studios, indipendentemente
        dagli scopi del collegamento stesso, secondo la legislazione Italiana ed Europea.
      </p>
      <p className="mt-5">
        <strong>1.5.</strong> L'informativa può subire modifiche a causa dell'introduzione di nuove norme al
        riguardo, si invita pertanto l'utente a controllare periodicamente la presente pagina.
      </p>
      <p className="mt-5">
        <strong>1.6.</strong> Se l'utente ha meno di quattordici anni, ai sensi dell'art.8, c.1 regolamento (UE)
        2016/679, e dell'Art. 2 - Quinquies del D.Lgs 196/2003, così come modificato dal
        D.Lgs 181/18, dovrà legittimare il suo consenso attraverso l'autorizzazione dei
        genitori o di chi ne fa le veci.
      </p>

      <h2 className="text-xl text-center my-20">2 - Titolare del trattamento</h2>
      <p className="mt-5">
        <strong>2.1.</strong> Il titolare del trattamento è la persona fisica o giuridica, l'autorità pubblica, il
        servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità
        e i mezzi del trattamento di dati personali. Si occupa anche dei profili sulla sicurezza.
      </p>
      <p className="mt-5">
        <strong>2.2.</strong> Relativamente al presente sito web il titolare del trattamento è: Chimera Studio
        S.R.L., e per ogni chiarimento o esercizio dei diritti dell'utente potrà contattarlo al
        seguente indirizzo mail: jeck@chimerastudioss.com.
      </p>

      <h2 className="text-xl text-center my-20">3 - DPO o Responsabile della protezione dei dati personali</h2>
      <p className="mt-5">
        <strong>3.1.</strong> Il responsabile della protezione dei dati personali è colui disegnato dal
        Titolare del trattamento e dal responsabile del trattamento dati in base all'art. 37 del
        Regolamento (UE), 2016/679.
      </p>
      <p className="mt-5">
        <strong>3.2.</strong> Il responsabile della protezione dei dati è Giacomo Scardellato, con possibilità di
        essere contattato al seguente indirizzo mail: jeck@chimerastudioss.com.
      </p>

      <h2 className="text-xl text-center my-20">4 - Luogo trattamento dati</h2>
      <p className="mt-5">
        <strong>4.1.</strong> Il trattamento dei dati generato dall'utilizzo di Chimera Studios avviene presso
        Via Frua 22, 20146 Milano (MI).
      </p>
      <p className="mt-5">
        <strong>4.2.</strong> In caso di necessità, i dati connessi al servizio newsletter possono essere trattati
        dal responsabile del trattamento o soggetti da esso incaricati a tal fine presso la
        relativa sede.
      </p>

      <h2 className="text-xl text-center my-20">5 - Base giuridica del trattamento</h2>
      <p className="mt-5">
        <strong>5.1.</strong> Il trattamento dei dati personali da parte di Chimera Studios si basa sul
        consenso – ai sensi dell'art. 6, par. 1, lett. a) del Regolamento UE 2016/679 –
        espresso dall'utente mediante la navigazione su questo sito web e la sua
        consultazione, così accettando la presente informativa.
      </p>
      <p className="mt-5">
        <strong>5.2.</strong> Il consenso è facoltativo e può essere revocato in qualsiasi momento mediante
        richiesta inviata a mezzo email a jeck@chimerastudioss.com precisando che, in tal
        caso, in assenza di consenso non potranno essere erogati alcuni servizi e la
        navigazione sul sito web potrebbe essere compromessa.
      </p>

      <h2 className="text-xl text-center my-20">6 - Dati di navigazione</h2>
      <p className="mt-5">
        <strong>6.1.</strong> I sistemi informatici e le procedure software preposte al funzionamento di questo
        sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la
        cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. Si
        tratta di informazioni che non sono raccolte per essere associate a interessati
        identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed
        associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
      </p>
      <p className="mt-5">
        <strong>6.2.</strong> In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei
        computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI
        (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo
        utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in
        risposta, il codice numerico indicante lo stato della risposta data dal server (buon
        fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente
        informatico dell'utente.
      </p>
      <p className="mt-5">
        <strong>6.3.</strong> Saranno raccolti i dati di navigazione esclusivamente nell'interesse legittimo di
        permettere all'utente la fruizione dei contenuti pubblicati sui siti Internet del Titolare
        ed una loro corretta amministrazione e gestione. Questi dati vengono utilizzati al solo
        fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il
        corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. I
        dati in questione potrebbero essere utilizzati per l'accertamento di responsabilità in
        caso di eventuali reati informatici ai danni del nostro sito: salva questa eventualità, in
        genere i dati sui contatti web non vengono conservati per più di sette giorni.
      </p>

      <h2 className="text-xl text-center my-20">7 - Cookies</h2>
      <p className="mt-5">
        <strong>7.1.</strong> L'utilizzo dei cookie avviene nel rispetto della relativa normativa europea
        (direttiva 2009/136/CE ha modificato la direttiva 2002/58/CE "E Privacy) e nazionale
        (Provvedimento Garante per la protezione dei dati personali dell'8 maggio 2014 e
        successivi chiarimenti nonché Linee Guida cookie e altri strumenti di tracciamento
        del 10 giugno 2021 n.231).
      </p>
      <p className="mt-5">
        <strong>7.2.</strong> Per avere informazioni complete riguardo ai cookies visitare la nostra cookie
        policy.
      </p>

      <h2 className="text-xl text-center my-20">8 - Tipologia di dati acquisiti</h2>
      <p className="mt-5">
        <strong>8.1.</strong> Chimera Studios raccoglie i dati degli utenti direttamente dal sito o da terze parti.
        I dati sono necessari per la navigazione del sito.
      </p>
      <p className="mt-5">
        <strong>8.2.</strong> I dati raccolti da Chimera Studios sono:
      </p>
      <ul className="mt-5 list-disc list-inside">
        <li>Nome, Cognome, Indirizzo Email, Numero di Cellulare (solo previa compilazione del form e dopo il consenso espresso dell'utente)</li>
      </ul>

      <h2 className="text-xl text-center my-20">9 - Dati forniti dall'utente</h2>
      <p className="mt-5">
        <strong>9.1.</strong> Qualora il sito consenta l'inserimento di commenti, oppure in caso di specifici
        servizi richiesti dall'utente, ivi compresi la possibilità di inviare il Curriculum Vitae per
        un eventuale rapporto lavorativo, il sito rileva automaticamente e registra alcuni dati
        identificativi dell'utente, compreso l'indirizzo mail. Tali dati si intendono
        volontariamente forniti dall'utente al momento della richiesta di erogazione del
        servizio.
      </p>
      <p className="mt-5">
        <strong>9.2.</strong> Inserendo un commento o altra informazione l'utente accetta espressamente
        l'informativa privacy. I dati ricevuti verranno utilizzati esclusivamente per l'erogazione
        del servizio richiesto e per il solo tempo necessario per la fornitura del servizio.
      </p>
      <p className="mt-5">
        <strong>9.3.</strong> Le informazioni che gli utenti del sito riterranno di rendere pubbliche tramite i
        servizi e gli strumenti messi a disposizione degli stessi, sono fornite dall'utente
        consapevolmente e volontariamente, esentando il presente sito da qualsiasi
        responsabilità in merito ad eventuali violazioni delle leggi. Spetta all'utente verificare
        di avere i permessi per l'immissione di dati personali di terzi o di contenuti tutelati
        dalle norme nazionali ed internazionali.
      </p>
      <p className="mt-5">
        <strong>9.4.</strong> L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati
        su questo sito comporta la successiva acquisizione dell'indirizzo del mittente,
        necessario per rispondere alle richieste, nonché degli eventuali altri dati personali
        inseriti nella missiva.
      </p>
      <p className="mt-5">
        <strong>9.5.</strong> Specifiche informative di sintesi verranno progressivamente riportate o
        visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.
      </p>

      <h2 className="text-xl text-center my-20">10 - Finalità del trattamento dati</h2>
      <p className="mt-5">
        <strong>10.1.</strong> I dati raccolti dal sito durante il suo funzionamento sono utilizzati per finalità
        sopra indicate e per le seguenti finalità:
      </p>
      <ul className="mt-5 list-disc list-inside">
        <li>Richiesta dati per finalità di Marketing</li>
        <li>Iscrizione a canale newsletter</li>
      </ul>

      <h2 className="text-xl text-center my-20">11 - Conservazione Dati</h2>
      <p className="mt-5">
        <strong>11.1.</strong> Conformemente a quanto prescritto dall'art. 5.1(c) del Regolamento, i sistemi
        informativi e i programmi informatici utilizzati da Chimera Studios sono configurati in
        modo da ridurre al minimo l'uso di dati personali e identificativi; tali dati saranno
        trattati solo nella misura necessaria per il conseguimento delle finalità indicate nella
        presente Policy.
      </p>
      <p className="mt-5">
        <strong>11.2.</strong> I dati verranno conservati per il periodo di tempo strettamente necessario al
        conseguimento delle finalità in concreto perseguite e, in ogni caso, il criterio utilizzato
        per determinare il periodo di conservazione è improntato al rispetto dei termini
        consentiti dalle leggi applicabili e dai principi di minimizzazione del trattamento e
        limitazione della conservazione.
      </p>
      <p className="mt-5">
        <strong>11.3.</strong> I dati rilevati dal sito non saranno forniti mai a terzi, a meno che non si tratti di
        legittima richiesta da parte dell'autorità giudiziaria e nei soli casi previsti dalla legge.
      </p>
      <p className="mt-5">
        <strong>11.4.</strong> I dati utilizzati a fini di sicurezza (blocco tentativi di danneggiamento del sito)
        sono conservati per il tempo strettamente necessario al raggiungimento del fine
        anteriormente indicato.
      </p>

      <h2 className="text-xl text-center my-20">12 - Pagamenti</h2>
      <p className="mt-5">
        <strong>12.1.</strong> Chimera Studios utilizza servizi di pagamento per eseguire pagamenti con
        carta di credito, bonifico bancario o altri strumenti. Chimera Studios non raccoglie o
        acquisisce i dati usati per il pagamento.
      </p>
      <p className="mt-5">
        <strong>12.2.</strong> I dati per il pagamento sono raccolti e acquisiti direttamente dal gestore del
        servizio di pagamento, come l'ente carta di credito, Paypal, Stripe o simili. Questi
        servizi possono effettuare invio di messaggi verso l'utente, per esempio email o sms
        di notifiche del pagamento.
      </p>
      <p className="mt-5">
        <strong>12.3.</strong> I dati acquisiti e l'utilizzo degli stessi da parte di servizi terzi sono regolamentati
        dalle rispettive Privacy Policy alle quali si prega di fare riferimento.
      </p>
      <ul className="mt-5 list-disc list-inside">
        <li>
          PayPal, Fornitore del servizio: PayPal, Inc. Finalità del servizio: effettuare
          pagamenti online Dati personali raccolti: tipologie di dati secondo quanto
          specificato dalla privacy policy del servizio Luogo del trattamento: secondo
          quanto specificato dalla privacy policy del servizio <a className='underline' href="https://www.paypal.com/it/webapps/mpp/ua/privacy-full">Privacy Policy</a>
        </li>
        <li>
          Stripe, Fornitore del servizio: Stripe, Inc. Finalità del servizio: effettuare
          pagamenti online Dati personali raccolti: tipologie di dati secondo quanto
          specificato dalla privacy policy del servizio. Luogo del trattamento: secondo
          quanto specificato dalla privacy policy del servizio <a className='underline' href="https://stripe.com/it/privacy">Privacy Policy</a>
        </li>
      </ul>

      <h2 className="text-xl text-center my-20">13 - Newsletter</h2>
      <p className="mt-5">
        <strong>13.1.</strong> Con il consenso previo dell'utente, e tramite registrazione volontaria via email,
        questo viene inserito in una lista di contatti a cui verranno inviate periodicamente
        delle email di aggiornamento, anche di promozione e marketing, derivanti dal
        presente sito.
      </p>
      <p className="mt-5">
        <strong>13.2.</strong> All'utente viene inviata una email (all'indirizzo email indicato) con all'interno un
        link su cui cliccare per confermare che l'utente sia realmente il proprietario
        dell'indirizzo email.
      </p>
      <p className="mt-5">
        <strong>13.3.</strong> Dati personali che potrebbero essere raccolti nella newsletter:
      </p>
      <ul className="mt-5 list-disc list-inside">
        <li>Nome, Cognome, Indirizzo Email, Numero di Cellulare</li>
      </ul>

      <h2 className="text-xl text-center my-20">14 - Moduli di contatto</h2>
      <p className="mt-5">
        <strong>14.1.</strong> L'utente può compilare il/i moduli di contatto/richiesta informazioni, inserendo i
        propri dati e acconsentendo al loro uso per rispondere alle richieste di natura indicata
        nella intestazione del modulo.
      </p>
      <p className="mt-5">
        <strong>14.2.</strong> Dati personali che potrebbero essere raccolti:
      </p>
      <ul className="mt-5 list-disc list-inside">
        <li>Nome, Cognome, Indirizzo Email, Numero di Cellulare</li>
      </ul>

      <h2 className="text-xl text-center my-20">15 - Diritti dell'utente</h2>
      <p className="mt-5">
        <strong>15.1.</strong> Viene garantito all'utente il rispetto dei suoi diritti nell'ambito della protezione
        dei dati personali. In linea con quanto ripreso e affermato dal GDPR, in relazione al
        trattamento dei propri dati personali, l'utente ha diritto di chiedere al Titolare:
      </p>
      <ul className="mt-5 list-disc list-inside">
        <li><strong>L'accesso:</strong> l'utente può chiedere conferma che sia o meno in essere un trattamento di dati che lo riguarda, oltre a maggiori chiarimenti circa le informazioni di cui alla presente informativa;</li>
        <li><strong>La rettifica:</strong> può chiedere di rettificare o integrare i dati che ha fornito, qualora inesatti o incompleti;</li>
        <li><strong>La cancellazione:</strong> può chiedere che i suoi dati vengano cancellati, qualora non siano più necessari alle nostre finalità, in caso di revoca del consenso o opposizione al trattamento, in caso di trattamento illecito, ovvero sussista un obbligo legale di cancellazione o si riferiscano a soggetti minori di anni quattordici;</li>
        <li><strong>La limitazione:</strong> può chiedere che i suoi dati siano trattati solo ai fini della conservazione, con esclusioni di altri trattamenti, per il periodo necessario alla rettifica dei suoi dati, in caso di trattamento illecito per il quale si opponga alla cancellazione, qualora lei debba esercitare i suoi diritti in sede giudiziaria e i dati conservati dal Titolare le possono essere utili e, infine, in caso di opposizione al trattamento e sia in corso una verifica sulla prevalenza dei motivi legittimi del Titolare rispetto ai suoi.</li>
        <li><strong>L'opposizione:</strong> può opporsi in qualunque momento al trattamento dei suoi dati, salvo che vi siano motivi legittimi del Titolare per procedere al trattamento che prevalgano sui suoi, per esempio per l'esercizio o la propria difesa in sede giudiziaria.</li>
        <li><strong>La portabilità:</strong> può chiedere di ricevere i suoi dati, o di farli trasmettere ad altro titolare da lei indicato, in un formato strutturato, di uso comune e leggibile da dispositivo automatico.</li>
        <li><strong>La revoca:</strong> può revocare il suo consenso all'utilizzo di cookie (Cookie Policy) in qualsiasi momento, poiché in questo caso costituisca la base del trattamento. La revoca del consenso comunque non pregiudica la liceità del trattamento basata sul consenso svolto prima della revoca stessa.</li>
      </ul>
      <p className="mt-5">
        <strong>15.2.</strong> In qualsiasi momento l'utente può chiedere di esercitare i suddetti diritti a
        Chimera Studios rivolgendosi all'indirizzo mail.
      </p>
      <p className="mt-5">
        <strong>15.3.</strong> Inoltre, l'utente ha diritto di proporre reclamo nei confronti dell'Autorità di
        controllo italiana: "Garante per la Protezione dei Dati Personali" nel caso in cui
        ritenga che i suoi diritti siano stati violati da Chimera Studios o nel caso in cui non
        ritenga soddisfacente la risposta di Chimera Studios alle sue richieste.
      </p>

      <h2 className="text-xl text-center my-20">16 - Sicurezza dati forniti</h2>
      <p className="mt-5">
        <strong>16.1.</strong> Il presente sito tratta i dati degli utenti in maniera lecita e corretta, adottando le
        opportune misure di sicurezza volte ad impedire accessi non autorizzati,
        divulgazione, modifica o distruzione non autorizzata dei dati. Il trattamento viene
        effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e
        con logiche strettamente correlate alle finalità indicate.
      </p>
      <p className="mt-5">
        <strong>16.2.</strong> Oltre al titolare, in alcuni casi, potrebbero avere accesso ai dati categorie di
        incaricati coinvolti nell'organizzazione del sito (personale amministrativo,
        commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni
        (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società
        informatiche, agenzie di comunicazione).
      </p>

      <h2 className="text-xl text-center my-20">17 - Modifiche al presente documento</h2>
      <p className="mt-5">
        <strong>17.1.</strong> Il presente documento, che costituisce la privacy policy di questo sito, è
        pubblicato nel nostro sito.
      </p>
      <p className="mt-5">
        <strong>17.2.</strong> Esso può essere soggetto a modifiche o aggiornamenti. Qualora si tratti di
        modifiche ed aggiornamenti rilevanti questi saranno segnalati con apposite notifiche
        agli utenti. Si invitano comunque gli utenti a consultare periodicamente la presente
        pagina per essere sempre aggiornati sulle ultime novità legislative.
      </p>
      <p className="mt-5">
        <strong>17.3.</strong> Le versioni precedenti del documento saranno comunque consultabili a questa
        pagina.
      </p>
      <p className="mt-5">
        <strong>17.4.</strong> Il documento è stato aggiornato in data 17/09/2024 per essere conforme alle
        disposizioni normative in materia, ed in particolare in conformità al Regolamento (UE)
        2016/679.
      </p>
    </div>
  );
}

export default PrivacyPolicy;