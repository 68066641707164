const servizi = [
	{
		key: 0,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/CONTENT+CREATION.jpg",
		id: "contentcreation",
		titolo: "Content Creation",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1:
			"Avete delle idee per raccontare la vostra realtà ma non sapere come fare?",
		descr2:
			"Ci pensiamo noi, ogni tipo di produzione che avete in mente noi la sappiamo fare!",

		hasCategories: true,
		contents: [
			{
				category: "Camera",
				isVideo: true,
				isVertical: true,
				items: [
					{
						client: "CANALI",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Canali+recap+evento.mp4",
					},
					{
						client: "SUN68",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/SUN68_reel.mp4",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/CS_LEC_pill+02+-+(9x16)_1.mp4",
					},
					{
						client: "LANGOSTERIA",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/BLANC_REEL+RECAP.mp4",
					},
				],
			},
			{
				category: "Content",
				isVideo: true,
				isVertical: true,
				items: [
					{
						client: "RUNNING",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/RUNNING_1.mp4",
					},
					{
						client: "Playstation",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/PLAYSTATION.mp4",
					},
					{
						client: "Harmont & Blaine",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/HARMONT%26BLAINE_1.mp4",
					},
					{
						client: "ALEKSANDRA",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/ALEKSANDRA.mp4",
					},
				],
			},
			{
				category: "IPhone",
				isVideo: true,
				isVertical: true,
				items: [
					{
						client: "WUDY",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/WUDY.mp4",
					},
					{
						client: "PILOT ROOM",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/PILOT+ROOM.mp4",
					},
					{
						client: "BBACK",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/BBACK.mp4",
					},
					{
						client: "Santa Lucia",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/GALBANI.mp4",
					},
				],
			},
		],
	},
	{
		key: 1,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/live++coverage.jpg",
		id: "livecoverage",
		titolo: "Live Coverage",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1: "Tutto ciò che è successo oggi è già vecchio",
		descr2:
			"Ad ogni vostro evento, noi saremo lì, registreremo i contenuti e li pubblicheremo, per un'esperienza live. Durante il vostro evento registreremo e monteremo con iPhone video che vi verranno consegnati durante la serata per raccontare in tempo reale tutto quello che avere realizzato	",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: true,
				isVertical: true,
				items: [
					{
						client: "LANGOSTERIA",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/REEL+RECAP+(1).mp4",
					},
					{
						client: "Santa Lucia",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Reel+recap.mp4",
					},
					{
						client: "1664 Blanc",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/reel.mp4",
					},
					{
						client: "Playstation",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/VIDEO+RECAP.mp4",
					},
				],
			},
		],
	},
	{
		key: 2,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/cgi+.jpg",
		id: "cgi",
		titolo: "CGI",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1: "Trasforma l’immaginazione in realtà.",
		descr2:
			"Utilizzeremo le nostre competenze e software 3D, per comunicare al meglio il tuo prodotto o evento",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: true,
				isVertical: true,
				items: [
					{
						client: "Playstation",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/PlayStation_CGI_Final_1.mp4",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/LEC.mp4",
					},
					{
						client: "MIAMO",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/MIAMO_1.mp4",
					},
					{
						client: "ACF FIORENTINA",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/FIORENTINA_1.mp4",
					},
				],
			},
		],
	},
	{
		key: 3,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/mini+commercial.jpg",
		id: "minicommercial",
		titolo: "Mini Commercial",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1: "Volete qualcosa di piu “cinematografico”, facciamo anche questo!",
		descr2:
			"Nel tempo abbiamo sviluppato la nostra vena artistica e ampliato i nostri studi di marketing che ci hanno permesso di affinare, migliorare e raggiungere la creazione di contenuti commercial, ogni tua idea sarà raccontata con l'obiettivo di narrare la visione che ti appartiene, tramite questi video potrai far conoscere l'essenza e l'energia della tua azienda.",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: true,
				isVertical: false,
				items: [
					{
						client: "Onitsuka Tiger",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/%5Breel%5D+Onitsuka+FW+-+ALL+-+16-9.mp4",
					},
					{
						client: "TECHNO GYM",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/1920x1080_1.mp4",
					},
					{
						client: "TAMRON",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/commercial-tamronV4+(1).mp4",
					},
					{
						client: "GAME 7 ATHLETICS",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/final+v2_1.mp4",
					},
					{
						client: "Radio Deejay",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/RADIO+DJ+2_1.mp4",
					},
					{
						client: "Radio Deejay",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/radio+djjj_1.mp4",
					},
				],
			},
		],
	},
	{
		key: 4,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/foto+e+video.jpg",
		id: "videofotoeventi",
		titolo: "Video/Foto Eventi",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1:
			"Il vostro evento verrà coperto a 360 con i nostri fotografi e videomaker!",
		descr2: "",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: true,
				isVertical: false,
				items: [
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/CS_LEC+v1_1.mp4",
					},
					{
						client: "GAME 7 ATHLETICS",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/GAME7ATHLE+ORIZZ+DEF-.mp4",
					},
					{
						client: "MUGLER x H&M",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/H%26M+-+EVENT+COVERAGE+(16-9)+-+SAMPLE+2.mp4",
					},
					{
						client: "CRAZY PIZZA PARIGI",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/CS_CRAZY+PIZZA+PARIGI+v3_1.mp4",
					},
				],
			},
			{
				//category: "Video",
				isVideo: false,
				isVertical: true,
				items: [
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_231026_CHAMPION_FIRENZE_42.jpg",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_231026_CHAMPION_FIRENZE_8.jpg",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_240518_GAME7_15.jpg",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_240518_GAME7_20.jpg",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_240518_GAME7_24.jpg",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_240523_A-MORE+COLAZIONE_17.JPG",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_240523_A-MORE+COLAZIONE_2.JPG",
					},
					{
						client: "LEC",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+CS_240523_A-MORE+COLAZIONE_7.JPG",
					},
				],
			},
		],
	},
	{
		key: 5,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/e-commerce.jpg",
		id: "ecommerce",
		titolo: "Foto E-commerce",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1: "Tutto ciò che si può fotografare può comunicare…",
		descr2:
			"...e noi lo sappiamo fare. Raccontare ogni oggetto, esprimere la sua essenza è il nostro obiettivo.",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: false,
				isVertical: true,
				items: [
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+15.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-15.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-3.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-AMBIENT+4.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-AMBIENT+5.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-AMBIENT-6.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-INDOSSATO-12.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Copia+di+A-MORE-INDOSSATO-3.jpg",
					},
				],
			},
		],
	},
	{
		key: 6,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/drone.jpg",
		id: "dronefpv",
		titolo: "Drone FPV",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1: "I nostri droni a tua disposizione.",
		descr2:
			"Dinamicità, agilità, sicurezza e massima performance da tutte le prospettive che desideri, i nostri droni saranno i tuoi occhi per creare qualcosa di unico. La loro elevata velocità permette di creare video adrenalinici, video sportivi, e riprendere i più bei paesaggi dell'Italia visti dall'alto.",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: true,
				isVertical: true,
				items: [
					{
						client: "READY2FLY",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/READY2FLY.mp4",
					},
					{
						client: "RALLY",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/reel+rally.mov",
					},
					{
						client: "ROMA SFX",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/roma+sfx+(1).MOV",
					},
					{
						client: "THE SPACE",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/THE+SPACE.mp4",
					},
				],
			},
		],
	},
	{
		key: 7,
		bg: "https://chimerastudio.s3.eu-north-1.amazonaws.com/editoriali2.jpg",
		id: "editoriali",
		titolo: "Editoriali",
		videoURL: "https://www.youtube.com/embed/7Hk9jct2ozY?si=5W7czU4quuMpcA2U",
		descr1:
			"Valorizza i tuoi prodotti attraerso immagini suggestive e coinvolgenti.",
		descr2:
			"Ti aiuteremo a raccontare una storia visiva che catturi l'attenzione del tuo pubblico e metta in risalto le caratteristiche uniche dei tuoi prodotti. Che si tratti di moda, design, tecnologia o gastronomia, i nostri scatti editoriali sono studiati per emozionare e convincere, guidando i tuoi clienti attraverso un viaggio visivo che rende il tuo prodotto irresistibile.",
		hasCategories: false,
		contents: [
			{
				//category: "Video",
				isVideo: false,
				isVertical: true,
				items: [
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Wudy_Volley-21.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/Wudy_Volley-9.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/amore+def+%C2%A9+Silvia+Violante+Rouge_-15.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/A_More_Tenerife+Final+Selection-40.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/A_More_Tenerife+Final+Selection-52.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/A_More_Tenerife+Final+Selection-58.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/240124+-+A-MORE+EDITORIAL+GIOIELLI-10.jpg",
					},
					{
						client: "Video Editing",
						url: "https://chimerastudio.s3.eu-north-1.amazonaws.com/240124+-+A-MORE+EDITORIAL+GIOIELLI-20.jpg",
					},
				],
			},
		],
	},
];

export default servizi;
