import React from "react";

function Insta() {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.0130984 12.9465C0.0130984 11.2799 -0.0222539 9.61232 0.023199 7.94672C0.0656217 6.40536 0.340359 4.90845 1.12417 3.53779C2.14635 1.75099 3.72609 0.773245 5.67855 0.314676C6.88255 0.0318576 8.11078 0.00559592 9.33902 0.00559592C12.0834 0.00559592 14.8277 -0.0146054 17.571 0.021757C19.0892 0.0419582 20.577 0.238921 21.979 0.928795C23.9759 1.9126 25.096 3.53173 25.596 5.64074C25.8293 6.62758 25.9081 7.63259 25.9081 8.64468C25.9081 11.6577 25.9172 14.6717 25.892 17.6848C25.8808 19.019 25.7041 20.3321 25.1778 21.5816C24.2405 23.8057 22.5638 25.1027 20.2295 25.6158C18.9033 25.9077 17.5599 25.9218 16.2135 25.9218C13.5197 25.9198 10.8268 25.9279 8.13402 25.8976C6.83406 25.8824 5.5432 25.7148 4.33314 25.2077C2.111 24.2764 0.82519 22.5674 0.308037 20.2432C0.0827928 19.2372 -3.24602e-05 18.2171 0.000977604 17.1888C0.00198767 15.7747 0.000977604 14.3606 0.000977604 12.9465C0.00501786 12.9465 0.00905812 12.9465 0.0120883 12.9465H0.0130984ZM12.949 2.48429C11.1986 2.48429 9.4471 2.44894 7.69767 2.49742C6.67144 2.52571 5.64825 2.70146 4.73919 3.23881C3.39681 4.03171 2.79381 5.29732 2.58977 6.77707C2.34332 8.56488 2.39281 10.3739 2.40897 12.1668C2.42614 13.999 2.42008 15.8333 2.4625 17.6666C2.4817 18.4797 2.56856 19.2786 2.77966 20.0614C3.12814 21.3543 3.89175 22.3108 5.12604 22.8623C6.17752 23.332 7.29869 23.4421 8.42996 23.4502C10.9551 23.4684 13.4803 23.4724 16.0044 23.4643C16.9953 23.4613 17.9872 23.4754 18.9771 23.3421C20.9295 23.0785 22.4254 22.227 23.0698 20.2554C23.3001 19.5503 23.4274 18.815 23.4304 18.0716C23.4425 14.8737 23.4335 11.6749 23.4446 8.47701C23.4476 7.61239 23.3456 6.76394 23.1375 5.92861C22.9022 4.98723 22.4264 4.19736 21.6557 3.58526C20.6396 2.77822 19.4336 2.54086 18.2003 2.49944C16.4519 2.44086 14.6994 2.48429 12.949 2.48429Z"
				fill="#DFDFDF"
			/>
			<path
				d="M12.9388 19.6537C9.24093 19.6517 6.2673 16.6468 6.26932 12.9136C6.27033 9.25613 9.29446 6.26937 12.9883 6.27442C16.6609 6.28048 19.6567 9.32481 19.6456 13.0408C19.6345 16.6549 16.5922 19.6558 12.9398 19.6537H12.9388ZM12.959 8.72888C10.5853 8.72888 8.72882 10.545 8.72478 12.8722C8.72074 15.3377 10.5328 17.1973 12.9418 17.2003C15.3104 17.2033 17.1881 15.3438 17.1871 12.9964C17.1871 10.5854 15.3478 8.7309 12.958 8.72989L12.959 8.72888Z"
				fill="#DFDFDF"
			/>
			<path
				d="M19.8761 7.64081C18.9216 7.64586 18.2842 7.01861 18.2802 6.07117C18.2761 5.13787 18.9276 4.46921 19.8498 4.46113C20.7528 4.45305 21.4558 5.14393 21.4609 6.04592C21.4649 6.97013 20.8023 7.63677 19.8761 7.64182V7.64081Z"
				fill="#DFDFDF"
			/>
		</svg>
	);
}

export default Insta;
