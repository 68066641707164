import logo_dodo from "./logo_dodo.png";
import logo_EA from "./logo_EA.png";
import logo_ferragamo from "./logo_ferragamo.png";
import logo_harmont from "./logo_harmont.jpeg";
import logo_HM from "./logo_HM.png";
import logo_hyrox from "./logo_hyrox.png";
import logo_ikea from "./logo_ikea.png";
import logo_kiton from "./logo_kiton.png";
import logo_la_piadineria from "./logo_la piadineria.png";
import logo_lec from "./logo_lec.png";
import logo_lualdi from "./logo_lualdi.png"; // Assuming lualdi.svg is also handled as a PNG
import logo_LVMH from "./logo_LVMH.png";
import logo_marangoni from "./logo_marangoni.png";
import logo_OT from "./logo_OT.webp";


export default [
	logo_dodo,
	logo_EA,
	logo_ferragamo,
	logo_harmont,
	logo_HM,
	logo_LVMH,
	logo_ikea,
	logo_kiton,
	logo_la_piadineria,
	logo_lec,
	logo_lualdi,
	logo_hyrox,
	logo_marangoni,
	logo_OT,
	
];
