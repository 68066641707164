import React from "react";

function Icon({ w }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : "30"}
      height="16"
      fill="none"
      viewBox="0 0 15 8"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="M.75 7.5L7.25 1l6.5 6.5"
      ></path>
    </svg>
  );
}

export default Icon;