import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { CiCircleAlert } from "react-icons/ci";
import { AddToNewsletter } from "../../../utils/Newsletter";
const JoinModal = ({
    isOpen,
    setIsOpen,
    back,
    setBack,
    done,
    setDone,
    fail,
    setFail,
}) => {
    const [formData, setFormData] = useState({
        name: "",
        nick: "",
        email: "",
        phone: "",
        role: "",
        description: "",
        portfolio: "",
        privacy: false,
        newsletter: false,
		type: "fornitori"
    });
    const [errors, setErrors] = useState({}); // Stato per gli errori

    // Funzione per gestire il cambiamento dei campi del form
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
        setErrors({ ...errors, [name]: "" }); // Resetta l'errore per il campo corrente
    };

    const validateForm = () => {
        const newErrors = {};

        // Verifica i campi obbligatori
        if (!formData.name) newErrors.name = "Il nome è obbligatorio.";
        if (!formData.portfolio) newErrors.portfolio = "Il link è obbligatorio";
        if (!formData.description)
            newErrors.description = "La descrizione è obbligatoria.";
        if (!formData.role) newErrors.role = "Il ruolo è obbligatorio.";
        if (!formData.nick) newErrors.nick = "Il nome d'arte è obbligatorio.";
        if (!formData.email)
            newErrors.email = "L'indirizzo email è obbligatorio.";
        if (!formData.phone)
            newErrors.phone = "Il numero di cellulare è obbligatorio.";
        if (!formData.privacy)
            newErrors.privacy = "È necessario accettare la privacy policy.";

        setErrors(newErrors);

        // Add error class to inputs with errors
        Object.keys(newErrors).forEach((key) => {
            const inputElement = document.querySelector(`[name="${key}"]`);
            if (inputElement) {
                inputElement.classList.add("error");
            }
        });

        // Remove error class from inputs without errors
        Object.keys(formData).forEach((key) => {
            if (!newErrors[key]) {
                const inputElement = document.querySelector(`[name="${key}"]`);
                if (inputElement) {
                    inputElement.classList.remove("error");
                }
            }
        });

        return Object.keys(newErrors).length === 0; // Ritorna true se non ci sono errori
    };

    // Funzione per inviare i dati al server
    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }
       
        try {
            const headers = {
                "Content-Type": "application/json",
            };
            await axios
                .post(
                    process.env.REACT_APP_API_URL + "/sendmail",
                    { data: formData, type: "fornitori" }, // Invia i dati correttamente formattati come JSON
                    { headers }
                )
                .then((res) => {
                    console.log(res.data); // Debug della risposta
                    if (res.data.success) {
                        setDone(true); // Mostra il messaggio di invio riuscito
                    } else {
                        setDone(true); // Chiudi il form
                        setFail(true); // Mostra il messaggio di invio fallito
                    }
                })
                .catch((err) => {
                    console.error(err); // Debug dell'errore
                });
        } catch (error) {
            console.error("Errore durante l'invio dei dati:", error);
            alert(
                "Si è verificato un errore durante l'invio dei dati. Riprova più tardi."
            );
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className=" p-8  w-[100vw] h-full flex justify-center items-center   "
                >
                    {!done ? (
                        <motion.div
                            initial={{ opacity: 0, x: 0, y: "100%" }}
                            animate={
                                back
                                    ? { opacity: 1, y: 0 }
                                    : {
                                          scale: 0.8,
                                          opacity: 0.5,
                                          x: "-50%",
                                          y: "-2%",
                                      }
                            }
                            transition={{
                                duration: 0.6,
                                ease: [0.54, 0.44, 0.43, 0.81],
                            }}
                            exit={{ opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                            className={`md:top-1/8 top-[15%] mb-12 bg-azzurro text-white p-6 rounded-lg w-full md:max-w-md max-w-[90%] shadow-xl cursor-default absolute overflow-hidden flex flex-col items-center justify-center gap-4`}
                        >
                            <h3 className="text-bianco font-semibold md:text-4xl text-3xl place-self-start">
                                Parlaci un po' di te
                            </h3>
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <label className="text-lg text-bianco ">
                                        Nome e cognome
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] place-self-end  ${
                                            !errors.name && "hidden"
                                        }`}
                                    >
                                        {errors.name}
                                    </label>
                                </div>
                                <input
                                    value={formData.name}
                                    onChange={handleChange}
                                    name="name"
                                    className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.name ? "error" : ""
                                    }`}
                                    type="text"
                                    placeholder="Es. Mario Rossi"
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <label className="text-xl text-bianco ">
                                        Nome d'arte
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] place-self-end  ${
                                            !errors.nick && "hidden"
                                        }`}
                                    >
                                        {errors.nick}
                                    </label>
                                </div>
                                <input
                                    value={formData.nick}
                                    onChange={handleChange}
                                    name="nick"
                                    className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.nick ? "error" : ""
                                    }`}
                                    type="text"
                                    placeholder="Nome d'arte"
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <label className="text-xl text-bianco ">
                                        Indirizzo email
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] place-self-end  ${
                                            !errors.email && "hidden"
                                        }`}
                                    >
                                        {errors.email}
                                    </label>
                                </div>
                                <input
                                    value={formData.email}
                                    onChange={handleChange}
                                    name="email"
                                    className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.email ? "error" : ""
                                    }`}
                                    type="text"
                                    placeholder="Es. info@mariorossi.com"
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <label className="text-xl text-bianco ">
                                        Numero di cellulare
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] place-self-end  ${
                                            !errors.phone && "hidden"
                                        }`}
                                    >
                                        {errors.phone}
                                    </label>
                                </div>
                                <input
                                    value={formData.phone}
                                    onChange={handleChange}
                                    name="phone"
                                    className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.phone ? "error" : ""
                                    }`}
                                    type="text"
                                    placeholder="Es. +39 123 456 7890"
                                />
                            </div>
                            <div className="w-full flex flex-auto items-center gap-2">
                                <input
                                    value={formData.privacy}
                                    onChange={handleChange}
                                    name="privacy"
                                    type="checkbox"
                                    className={` w-5 aspect-square accent-bianco bg-bianco `}
                                />
                                <div className="flex flex-col">
                                    <label className="text-bianco text-xs">
                                        Ho letto e accettato la{" "}
                                        <a
                                            className="cursor-pointer underline"
                                            href="/privacy-policy"
                                        >
                                            privacy policy
                                        </a>{" "}
                                        e i{" "}
                                        <a
                                            className="cursor-pointer underline"
                                            href="/condizioni"
                                        >
                                            termini e condizioni
                                        </a>
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] ${
                                            !errors.privacy && "hidden"
                                        }`}
                                    >
                                        {errors.privacy}
                                    </label>
                                </div>
                            </div>
                            <div className="w-full flex flex-auto gap-2 items-center">
                                <input
                                    value={formData.newsletter}
                                    onChange={handleChange}
                                    type="checkbox"
									name="newsletter"
                                    className={` w-5 aspect-square accent-bianco bg-bianco `}
                                />
                                <label className="text-bianco text-sm">
                                    Vorrei iscrivermi alla vostra{" "}
                                    <span className="font-semibold">
                                        Newsletter
                                    </span>
                                </label>
                            </div>
                            <button
                                className="uppercase text-bianco cursor-pointer"
                                onClick={() => {
                                    setIsOpen(false);
                                    window.history.back();
                                }}
                            >
                                indietro
                            </button>
                            <button
                                onClick={() => setBack(false)}
                                className="uppercase text-xl py-1 px-2 rounded-full md:w-[30%] hover:w-[35%] transition-all duration-300 text-azzurro font-bold bg-bianco"
                            >
                                <span>prosegui</span>
                            </button>
                        </motion.div>
                    ) : (
                        ""
                    )}
                    {!back && !done ? (
                        <motion.div
                            initial={{ opacity: 0, x: "100%" }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{
                                duration: 0.6,
                                ease: [0.54, 0.44, 0.43, 0.81],
                            }}
                            exit={{ opacity: 0, x: "100%" }}
                            onClick={(e) => e.stopPropagation()}
                            className="md:top-1/8 top-[15%] mb-12 bg-azzurro text-white p-6 rounded-lg w-full md:max-w-md max-w-[90%] shadow-xl cursor-default absolute overflow-hidden flex flex-col items-center justify-center gap-4"
                        >
                            <h3 className="text-bianco font-semibold text-4xl place-self-start">
                                Cosa fai nel mondo della comunicazione
                            </h3>
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <label className="text-xl text-bianco ">
                                        Qual è il tuo ruolo?
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] place-self-end  ${
                                            !errors.role && "hidden"
                                        }`}
                                    >
                                        {errors.role}
                                    </label>
                                </div>
                                <input
                                    value={formData.role}
                                    onChange={handleChange}
                                    name="role"
                                    className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.role ? "error" : ""
                                    }`}
                                    type="text"
                                    placeholder="Di cosa ti occupi?"
                                />
                            </div>
                            <div className="w-full flex flex-col">
                                <label
                                    className={`text-xs text-[#ff0000] place-self-end ${
                                        !errors.description && "hidden"
                                    }`}
                                >
                                    {errors.description}
                                </label>
                                <textarea
                                    value={formData.description}
                                    onChange={handleChange}
                                    name="description"
                                    className={`w-full h-52 rounded-xl text-xl px-4 py-2 bg-bianco  focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.description ? "error" : ""
                                    }`}
                                    placeholder="Presentati"
                                ></textarea>
                            </div>
                            {/*<div className="flex gap-2 w-full relative justify-center items-center cursor-pointer h-28 ">
								<svg
									className="absolute w-full "
									viewBox="0 0 506 135"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="0.5"
										y="0.5"
										width="505"
										height="134"
										rx="19.5"
										stroke="white"
										stroke-dasharray="30 30"
									/>
								</svg>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="47.2" // 80% of 59
									height="54.4" // 80% of 68
									fill="none"
									viewBox="0 0 59 68"
								>
									<path
										fill="#fff"
										d="M29.4 47.15a1.63 1.63 0 01-1.628-1.629V14.467a3.82 3.82 0 00-3.816-3.815h-2.443L29.4 2.767l7.887 7.887h-2.444a3.82 3.82 0 00-3.815 3.816V45.52A1.63 1.63 0 0129.4 47.15z"
									></path>
									<path
										fill="#fff"
										d="M29.4 6.128l2.76 2.76a6.24 6.24 0 00-2.76 2.629 6.24 6.24 0 00-2.76-2.63l2.76-2.759zM29.4 0c-.369 0-.735.14-1.017.42L18.227 10.577c-.906.905-.264 2.453 1.017 2.453h4.714c.794 0 1.438.644 1.438 1.438v31.052a4.006 4.006 0 008.01 0V14.467c0-.794.645-1.438 1.439-1.438h4.714c1.28 0 1.92-1.548 1.017-2.453L30.417.42C30.137.14 29.768 0 29.4 0z"
									></path>
									<path
										fill="#fff"
										d="M50.877 20.217h-6.264a2.378 2.378 0 000 4.755h6.264a3.175 3.175 0 013.17 3.17V59.71a3.17 3.17 0 01-3.168 3.169H7.923a3.17 3.17 0 01-3.169-3.169V28.142a3.173 3.173 0 013.169-3.17h6.264a2.378 2.378 0 000-4.755H7.923A7.922 7.922 0 000 28.14v31.57a7.922 7.922 0 007.923 7.923h42.954a7.926 7.926 0 007.925-7.925V28.142a7.926 7.926 0 00-7.925-7.925z"
									></path>
								</svg>
								<div className="flex flex-col">
									<p className="text-bianco uppercase text-xl">carica il </p>
									<p className="text-bianco uppercase text-xl">tuo portfolio</p>
								</div>
								<input
									type="file"
									className="opacity-0 h-full absolute cursor-pointer"
								/>
							</div>*/}
                            <div className="w-full">
                                <div className="w-full flex justify-between">
                                    <label className="text-xl text-bianco ">
                                        Portfolio
                                    </label>
                                    <label
                                        className={`text-xs text-[#ff0000] place-self-end  ${
                                            !errors.portfolio && "hidden"
                                        }`}
                                    >
                                        {errors.portfolio}
                                    </label>
                                </div>
                                <input
                                    value={formData.portfolio}
                                    onChange={handleChange}
                                    name="portfolio"
                                    className={` rounded-xl text-xl px-4 py-2 bg-bianco w-full focus:outline-none focus:ring-2 focus:ring-azzurro focus:ring-opacity-50 ${
                                        errors.portfolio ? "error" : ""
                                    }`}
                                    type="text"
                                    placeholder="Inserisci il link al tuo portfolio"
                                />
                            </div>

                            <button
                                className="uppercase text-bianco cursor-pointer"
                                onClick={() => setBack(1)}
                            >
                                indietro
                            </button>
                            <button
                                type="submit"
                                className="uppercase text-xl py-1 px-2 rounded-full md:w-[20%] hover:w-[25%] transition-all duration-300 text-azzurro font-bold bg-bianco"
                                onClick={() => handleSubmit()}
                            >
                                invia
                            </button>
                        </motion.div>
                    ) : (
                        ""
                    )}
                    {done && !fail ? (
                        <motion.div
                            initial={{ opacity: 0, x: 0, y: "100%" }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.6,
                                ease: [0.54, 0.44, 0.43, 0.81],
                            }}
                            exit={{ opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                            className={` text-white p-6 rounded-lg w-full max-w-md absolute overflow-hidden flex flex-col items-center justify-center gap-7`}
                        >
                            <h3 className="text-bianco text-4xl text-center">
                                Inviato con successo
                            </h3>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="187"
                                height="187"
                                fill="none"
                                viewBox="0 0 234 234"
                            >
                                <circle
                                    cx="117.123"
                                    cy="117.242"
                                    r="109.559"
                                    stroke="#D9D9D9"
                                    strokeWidth="14.024"
                                ></circle>
                                <path
                                    stroke="#D9D9D9"
                                    strokeLinecap="round"
                                    strokeWidth="14.024"
                                    d="M65.412 111.107l39.027 35.058 64.396-57.847"
                                ></path>
                            </svg>
                            <div>
                                <p className="text-bianco text-center text-lg">
                                    Grazie per averci raccontato di te.
                                </p>
                                <p className="text-bianco text-center text-lg">
                                    A presto!
                                </p>
                            </div>
                            <button
                                className="uppercase text-bianco cursor-pointer hidden"
                                onClick={() => setIsOpen(false) || setDone(0)}
                            >
                                chiudi
                            </button>
                        </motion.div>
                    ) : (
                        ""
                    )}
                    {fail ? (
                        <motion.div
                            initial={{ opacity: 0, x: 0, y: "100%" }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.6,
                                ease: [0.54, 0.44, 0.43, 0.81],
                            }}
                            exit={{ opacity: 0 }}
                            onClick={(e) => e.stopPropagation()}
                            className={` text-white p-6 rounded-lg w-full max-w-xl absolute overflow-hidden flex flex-col items-center justify-center gap-7`}
                        >
                            <CiCircleAlert className="text-bianco w-44 h-44" />
                            <h3 className="text-bianco text-4xl text-center">
                                Qualcosa è andato storto
                            </h3>
                            <div>
                                <p className="text-bianco text-center text-lg">
                                    Non siamo riusciti a inviare il tuo
                                    messaggio, riprova più tardi.
                                </p>
                                <p className="text-bianco text-center text-lg">
                                    Se il problema persiste, contattaci a{" "}
                                    <a
                                        className="hover:underline cursor-pointer"
                                        href="mailto:jeck@chimerastudioss.com"
                                    >
                                        jeck@chimerastudioss.com
                                    </a>
                                </p>
                            </div>
                            <button
                                className="uppercase text-bianco cursor-pointer hidden"
                                onClick={() => setIsOpen(false) || setDone(0)}
                            >
                                chiudi
                            </button>
                        </motion.div>
                    ) : (
                        ""
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default JoinModal;
