import axios from "axios";

export const AddToNewsletter = async (email) => {
    const options = {
        method: "POST",
        url: "https://api.brevo.com/v3/contacts",
        headers: {
            accept: "application/json",
            "content-type": "application/json",
            "api-key": process.env.REACT_APP_BREVO_API_KEY,
        },
        data: { updateEnabled: false, email: email },
    };

    await axios
        .request(options)
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });
};
