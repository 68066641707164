import React, { useContext, useState } from "react";
import CornerNav from "./CornerNav.jsx";
import Chimera from "../../../assets/SVG/Chimera.jsx";
import Carrello from "../../../assets/SVG/Carrello.jsx";
import { CartContext } from "../../../Context/CartContext.jsx";
import { AnimatePresence } from "framer-motion";
import Cart from "../../../Pages/Store/Cart.jsx";

function Navbar() {
	const [isCartVisible, setCartVisible] = useState(false);
	const context = useContext(CartContext);
	const slug = window.location.pathname;
  
	if (!context) {
	  // Handle the case where CartContext is not available
	  return <div>Error: Cart context is not available</div>;
	}
  
	const { cartItems } = context;
  
	const toggleCartVisibility = () => {
	  setCartVisible(!isCartVisible);
	};
  

    return (
        <div className="w-full h-[10vh] z-[55] flex fixed top-0 bg-nero justify-between items-center md:px-[5%] lg:px-[10%]  px-[1rem] ">
            <div
                onClick={() => {
                    window.scroll(0, 0);
                    window.location.href = "/";
                }}
                className="flex items-center cursor-pointer"
            >
                <div className="2xl:hidden ">
                    <Chimera w="150" />
                </div>
                <div className="2xl:block hidden">
                    <Chimera w="200" />
                </div>
            </div>
            <div className="flex gap-20 items-center">
                {(slug === "/store" || slug === "/checkout") && (
                    <div className="">
                        <button
                            onClick={toggleCartVisibility}
                            className="relative"
                        >
                            <Carrello className="w-10" />
                            <span className="absolute top-[15%] right-[15%] text-bianco bg-red-500  rounded-full text-xs w-5 h-5 flex items-center justify-center">
                                {cartItems.length}
                            </span>
                        </button>
                    </div>
                )}
                <AnimatePresence>{isCartVisible && <Cart />}</AnimatePresence>
                <CornerNav />
            </div>
        </div>
    );
}

export default Navbar;
