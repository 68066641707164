import React, { useCallback, useContext } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import { CartContext } from '../../Context/CartContext';

const stripePromise = loadStripe("pk_live_51PaZGDK8lVpVPKLOJZ73J14Avhf6BWNqX3GLGNc8DqIxQp4z5VTmdAe7CaZUVGsALBRSlYLu8vqVlw8rSbIKmivy00Nh06cIKV");

const CheckoutForm = () => {
  const { cartItems } = useContext(CartContext);

  const fetchClientSecret = useCallback(async () => {
    const lineItems = cartItems.map(item => ({
      price_data: {
        currency: "EUR",
        product_data: {
        
          name: item.title,
          images: [item.image1],
        },
        unit_amount: item.price * 100,
      },
      adjustable_quantity: {
        enabled: true,
        minimum: 1,
        maximum: 10,
      },
      quantity: 1,
      metadata: {
        product_id: item.id, // aggiungi l'ID del prodotto qui
      }
    }));

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/create-checkout-session", { line_items: lineItems }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      return response.data.clientSecret;
    } catch (error) {
      console.error('Error fetching client secret:', error);
      throw error;
    }
  }, [cartItems]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="h-[120vh] mt-28">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

export default CheckoutForm;
