import { SiInstagram, SiTiktok } from "react-icons/si";
import { FaAngleDown } from "react-icons/fa6";
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

function CornerNav() {
	return (
		<div className="">
			<Nav />
		</div>
	);
}
export default CornerNav;

const Nav = () => {
	const [active, setActive] = useState(false);
	const slug = window.location.pathname;
	console.log(slug);
	

	return (
		<div className="z-50">
			<HamburgerButton active={active} setActive={setActive} />
			<AnimatePresence>
				{active && <LinksOverlay setActive={setActive} />}
			</AnimatePresence>
		</div>
	);
};

const LinksOverlay = ({ setActive }) => {
	return (
		<div className="relative">
			<div className="w-full h-full fixed top-0 left-0 z-10 bg-black opacity-50"></div>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.3, ease: "linear" } }} // Faster entrance
				exit={{ opacity: 0, transition: { duration: 0.3, ease: "linear" } }} // Slower exit as before
				className="fixed right-0 bg-nero/50  top-0 z-40 h-full w-full overflow-hidden"
			>
				<motion.div
					initial={{ x: "100%" }} // Start off-screen to the right
					animate={{ x: 0, transition: { duration: 0.3, ease: "linear" } }} // Faster entrance
					exit={{ x: "100%", transition: { duration: 0.3, ease: "linear" } }} // Slower exit as before
					className="md:w-1/2 w-full z-50 right-0 bg-nero fixed h-full"
				>
					<LinksContainer />
					<FooterCTAs />
				</motion.div>
			</motion.div>
		</div>
	);
};

const LinksContainer = () => {
	const [serv, setServ] = useState(0);
	return (
		<motion.div className="space-y-4 mt-5 p-12 pl-4 md:pl-20">
			<motion.div className="space-y-4 ">
				{LINKS.map((l, idx) => {
					if (l.title === "servizi") {
						return (
							<div className="">
								<button
									onClick={() => {
										setServ(!serv);
									}}
									className={` flex ${
										!serv ? "items-center" : "items-start"
									} gap-2 uppercase text-2xl font-semibold text-[#fff]  hover:text-violet-50 hover:pl-5 pl-0 transition-all duration-150`}
								>
									<span>I NOSTRI SERVIZI</span>{" "}
									<FaAngleDown
										className={`${
											serv && "rotate-180"
										} transition-all duration-200`}
									/>
								</button>
								<div className="mt-3">
								{SERVIZI.map((s, idx) => {
									return (
										<NavServ serv={serv} idx={SERVIZI.length} href={s.href}>
											{s.title}
										</NavServ>
									);
								})}
								</div>
							</div>
						);
					} else {
						return (
							<NavLink key={l.title} href={l.href} idx={idx}>
								{l.title}
							</NavLink>
						);
					}
				})}
			</motion.div>
		</motion.div>
	);
};

const NavLink = ({ children, href, idx }) => {
	return (
		<button
			onClick={() => {
				window.location.href = href;
			}}
			className="block uppercase text-2xl font-semibold text-[#fff]  hover:text-violet-50 hover:pl-5 pl-0 transition-all duration-150 "
		>
			{children}
		</button>
	);
};
const NavServ = ({ children, href, idx, serv }) => {
	return (
		<button
			className={`${
				serv ? "block" : "hidden"
			}  text-xl font-light text-[#fff] cursor-pointer pl-5 hover:pl-10 transition-all duration-150`}
			onClick={() => {
				window.location.href = "/servizi" + href;
			}}
		>
			{children}
		</button>
	);
};

const HamburgerButton = ({ active, setActive }) => {
	return (
		<>
			<motion.div
				initial={false}
				animate={active ? "open" : "closed"}
				variants={UNDERLAY_VARIANTS}
				style={{ top: 10, right: 16 }}
				className="fixed z-10 rounded-xl "
			/>

			<motion.button
				initial={false}
				animate={active ? "open" : "closed"}
				onClick={() => setActive((pv) => !pv)}
				className={`group relative z-50 h-20 w-20 bg-bianco/0 transition-all ${
					active ? "rounded-bl-xl rounded-tr-xl" : "rounded-xl"
				}`}
			>
				<motion.span
					variants={HAMBURGER_VARIANTS.top}
					className="absolute block h-[1px] w-10 bg-bianco"
					style={{ y: "-50%", left: "50%", x: "-50%" }}
				/>
				<motion.span
					variants={HAMBURGER_VARIANTS.middle}
					className="absolute block h-[1px] w-10 bg-bianco"
					style={{ left: "50%", x: "-50%", top: "50%", y: "-50%" }}
				/>
				<motion.span
					variants={HAMBURGER_VARIANTS.bottom}
					className="absolute block h-[1px] w-5 bg-bianco"
					style={{ x: "-50%", y: "50%" }}
				/>
			</motion.button>
		</>
	);
};

const FooterCTAs = () => {
	return (
		<>
			<div className="absolute bottom-6 left-6 flex md:pl-14 pr-2 gap-4 flex-col">
				<p className="block uppercase  font-semibold text-[#fff] transition-colors hover:text-violet-50 md:text-xl text-3xl">
					SEGUICI SUI SOCIAL
				</p>
				<div className="md:flex hidden gap-2 flex-col">
					{SOCIAL_CTAS.map((l, idx) => {
						return (
							<div key={idx} href={l.href}>
								{" "}
								<div className="flex items-center  gap-4">
									<l.Component className="text-xl text-bianco" />
									<a href={l.href} className="text-xl font-light text-bianco hover:underline">{l.text}</a>
								</div>
							</div>
						);
					})}
				</div>
				<div className="flex md:hidden gap-2 flex-col">
					{SOCIAL_CTAS_phone.map((l, idx) => {
						return (
							<div key={idx} href={l.href}>
								{" "}
								<div className="flex items-center  gap-4">
									<l.Component className="text-xl text-bianco" />
									<a href={l.href} className="text-xl font-light text-bianco">{l.text}</a>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

const LINKS = [
	{
		title: "chi siamo",
		href: "/chisiamo",
	},
	{
		title: "servizi",
	},
	{
		title: "i nostri clienti",
		href: "/perchi",
	},
	{
		title: "MERCH",
		href: "/store",
	},
	{
		title: "TEAM",
		href: "/team",
	},
	{
		title: "contattaci",
		href: "/contatti",
	},
];
const SERVIZI = [
	{
		title: "Content Creation",
		href: "/contentcreation",
	},
	{
		title: "Live Coverage",
		href: "/livecoverage",
	},
	{
		title: "CGI",
		href: "/cgi",
	},
	{
		title: "Mini Commercial",
		href: "/minicommercial",
	},
	{
		title: "Video/Foto Eventi",
		href: "/videofotoeventi",
	},
	{
		title: "E-commerce",
		href: "/ecommerce",
	},
	{
		title: "Drone FPV",
		href: "/dronefpv",
	},
	{
		title: "Editoriali",
		href: "/editoriali",
	},
];

const SOCIAL_CTAS = [
	{
		Component: SiInstagram,
		href: "https://www.instagram.com/chimera.studioss/",
		text: "@chimera.studioss",
	},
	{
		Component: SiTiktok,
		href: "https://www.tiktok.com/@chimerafamily",
		text: "chimerafamily",
	},
];
const SOCIAL_CTAS_phone = [
	{
		Component: SiInstagram,
		href: "http://instagram.com/_u/chimera.studioss/",
		text: "@chimera.studioss",
	},
	{
		Component: SiTiktok,
		href: "http://tiktok.com/@chimerafamily/",
		text: "chimerafamily",
	},
];

const UNDERLAY_VARIANTS = {
	open: {
		width: "calc(100% - 32px)",
		height: "calc(100vh - 32px)",
		transition: {
			duration: 0.2, // Reduced duration for quicker animation
			type: "spring",
			mass: 2, // Reduced mass for quicker animation
			stiffness: 500, // Increased stiffness for quicker animation
			damping: 40, // Slightly reduced damping for a bit more bounciness
		},
	},
	closed: {
		width: "80px",
		height: "80px",
		transition: {
			delay: 0.5, // Reduced delay for quicker transition to closed state
			type: "spring",
			mass: 1,
			stiffness: 500, // Increased stiffness for quicker animation
			damping: 40, // Slightly reduced damping for a bit more bounciness
		},
	},
};

const HAMBURGER_VARIANTS = {
	top: {
		open: {
			transition: { duration: 0.4 },
			rotate: ["0deg", "0deg", "45deg"],
			top: ["35%", "50%", "50%"],
		},
		closed: {
			transition: { duration: 0.4 },
			rotate: ["45deg", "0deg", "0deg"],
			top: ["50%", "50%", "35%"],
		},
	},
	middle: {
		open: {
			transition: { duration: 0.4 },
			rotate: ["0deg", "0deg", "-45deg"],
		},
		closed: {
			transition: { duration: 0.4 },
			rotate: ["-45deg", "0deg", "0deg"],
		},
	},
	bottom: {
		open: {
			transition: { duration: 0.4 },
			rotate: ["0deg", "0deg", "45deg"],
			bottom: ["35%", "50%", "50%"],
			left: "50%",
		},
		closed: {
			transition: { duration: 0.4 },
			rotate: ["45deg", "0deg", "0deg"],
			bottom: ["50%", "50%", "35%"],
			left: "calc(50% + 10px)",
		},
	},
};
