import React from "react";

function CTA({ titolo, link }) {
    return (
        <button
            onClick={() => (window.location.href = link)}
            className="px-6 py-3 z-[999] relative cursor-pointer rounded-full bg-azzurro  text-2xl hover:scale-110 duration-300 transition-all hover:bg-bianco hover:text-azzurro    "
        >
            {titolo}
        </button>
    );
}

export default CTA;
