import React, { useEffect, useRef, useState } from "react";
import ArrowUp from "../../assets/SVG/ArrowUp";
import {
    motion,
    useViewportScroll,
    useTransform,
    AnimatePresence,
    useAnimation,
} from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import servizi from "./ListaServizi";
import { IoCloseOutline } from "react-icons/io5";
import { AsyncImage } from "loadable-image";
import VideoPlayer from "../../Components/Atoms/Players/VideoPlayer";
import Contattaci from "../../Components/Molecules/Contattaci/Contattaci";
import Sfumatura1 from "../../assets/sfumature/1.png";
import Sfumatura2 from "../../assets/sfumature/2.png";

function Servizi() {
    const { scrollYProgress } = useViewportScroll();
    const [servizio, setServizio] = useState({});
    const [loaded, setLoaded] = useState(false);
    const x = useTransform(scrollYProgress, [0, 1], [0, -4200]); // Adjust to control the distance of movement
    const [showArrows, setShowArrows] = useState(true);
    const [scrollProgress, setScrollProgress] = useState(0);
    const { scrollY } = useViewportScroll();
    const [isDragging, setIsDragging] = useState(false);

    const controls = useAnimation();
    const controlsPhone = useAnimation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const documentHeight = document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;
            const scrollableHeight = documentHeight - windowHeight;
            const progress = scrollTop / scrollableHeight;

            setScrollProgress(progress);
        };

        handleScroll(); // Initialize on mount
        window.addEventListener("scroll", handleScroll); // Update on scroll

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        controls.start({
            y: -scrollProgress * 3200, // Adjust the multiplier as needed
            transition: { type: "tween", ease: "easeOut", duration: 0 },
        });
    }, [scrollProgress, controls]);
    useEffect(() => {
        controlsPhone.start({
            y: -scrollProgress * 7000, // Adjust the multiplier as needed
            transition: { type: "tween", ease: "easeOut", duration: 0 },
        });
    }, [scrollProgress, controlsPhone]);

    const slug = window.location.pathname.split("/")[2];

    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className + "relative"} onClick={onClick}>
                <div
                    className={`${
                        showArrows ? "opacity-100" : "opacity-0"
                    } absolute right-0 top-0 flex justify-center items-center bg-nero/50  h-full z-[99]`}
                >
                    <svg
                        className=" cursor-pointer hover:scale-90 transition-all duration-300"
                        xmlns="http://www.w3.org/2000/svg"
                        height="60px"
                        viewBox="0 -960 960 960"
                        width="62px"
                        fill="#f2f2f2"
                    >
                        <path d="m309.67-81.33-61-61.67L587-481.33 248.67-819.67l61-61.66 400 400-400 400Z" />
                    </svg>
                </div>
            </div>
        );
    };
    const NextArrowFoto = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className + "relative"} onClick={onClick}>
                <div
                    className={`${"opacity-100"} absolute right-0 top-0 flex justify-center items-center bg-nero/50  h-full z-[99]`}
                >
                    <svg
                        className=" cursor-pointer hover:scale-90 transition-all duration-300"
                        xmlns="http://www.w3.org/2000/svg"
                        height="40px"
                        viewBox="0 -960 960 960"
                        width="42px"
                        fill="#f2f2f2"
                    >
                        <path d="m309.67-81.33-61-61.67L587-481.33 248.67-819.67l61-61.66 400 400-400 400Z" />
                    </svg>
                </div>
            </div>
        );
    };

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className + "relative"} onClick={onClick}>
                <div
                    className={`${
                        showArrows ? "opacity-100" : "opacity-0"
                    } absolute left-0 top-0 flex justify-center items-center bg-nero/50 z-[99] h-full`}
                >
                    <svg
                        className="rotate-180 cursor-pointer hover:scale-90 transition-all duration-300"
                        xmlns="http://www.w3.org/2000/svg"
                        height="60px"
                        viewBox="0 -960 960 960"
                        width="64px"
                        fill="#f2f2f2"
                    >
                        <path d="m309.67-81.33-61-61.67L587-481.33 248.67-819.67l61-61.66 400 400-400 400Z" />
                    </svg>
                </div>
            </div>
        );
    };

    const PrevArrowFoto = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className + "relative"} onClick={onClick}>
                <div
                    className={`${"opacity-100"} absolute left-0 top-0 flex justify-center items-center bg-nero/50 z-[99] h-full`}
                >
                    <svg
                        className="rotate-180 cursor-pointer hover:scale-90 transition-all duration-300"
                        xmlns="http://www.w3.org/2000/svg"
                        height="40px"
                        viewBox="0 -960 960 960"
                        width="44px"
                        fill="#f2f2f2"
                    >
                        <path d="m309.67-81.33-61-61.67L587-481.33 248.67-819.67l61-61.66 400 400-400 400Z" />
                    </svg>
                </div>
            </div>
        );
    };

    const settings = {
        dots: false,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: () => setIsDragging(true), // Imposta isDragging a true all'inizio del trascinamento
        afterChange: () => setIsDragging(false), // Reimposta isDragging a false alla fine del trascinamento
    };
    const settingsPhone = {
        dots: false,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    useEffect(() => {
        setServizio(servizi.find((servizio) => servizio.id === slug));
        setLoaded(true);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [indice, setIndice] = useState(0);

    const SpringModal = ({
        isOpen,
        setIsOpen,
        foto,
        indice,
        setIndice,
        url,
    }) => {
        const slide = (segno) => {
            if (segno === "next") {
                if (indice === foto.length - 1) {
                    setIndice(0);
                } else {
                    setIndice(indice + 1);
                }
            } else {
                if (indice === 0) {
                    setIndice(foto.length - 1);
                } else {
                    setIndice(indice - 1);
                }
            }
        };
        return (
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => setIsOpen(false)}
                        className="bg-nero backdrop-blur-md p-8 fixed inset-0 z-50 grid place-items-center cursor-pointer select-none"
                    >
                        <motion.div
                            initial={{ opacity: 0, rotate: "0deg" }}
                            animate={{ opacity: 1, rotate: "0deg" }}
                            transition={{ duration: 0.2 }}
                            exit={{ opacity: 0, rotate: "0deg" }}
                            onClick={(e) => e.stopPropagation()}
                            className="flex flex-col items-center justify-center bg-white p-1 md:p-6 rounded-lg w-full md:h-[80vh] h-[60vh] cursor-default relative overflow-hidden"
                        >
                            <PrevArrowFoto
                                className="absolute  w-20 h-20 left-20 top-20"
                                onClick={() => slide("prev")}
                            />
                            <IoCloseOutline
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                className="cursor-pointer place-self-end absolute top-[2%] text-5xl text-bianco  rounded-full hover:scale-90 transition-all duration-300"
                            />
                            <div className="h-full">
                                <div
                                    style={{ backgroundImage: `url(${url})` }}
                                    className=" h-full aspect-auto w-[1000px] text-bianco bg-contain bg-no-repeat bg-center"
                                ></div>
                            </div>
                            <NextArrowFoto
                                className="absolute  w-20 h-20 right-20 top-20"
                                onClick={() => slide("next")}
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    return (
        <div id="my-scrollbar" className="">
            <div className="fixed hidden w-[100vw] h-[100vh]  rotate-90 right-[-90%] top-[-30%] scale-150 opacity-30 brightness-0 invert -z-40">
                <img src={require("../../assets/sfumature/1.png")} alt="" />
            </div>
            <motion.div
                animate={controls}
                className="hidden md:block fixed w-[100vw] h-[100vh] top-0 left-0 bg-gradient-to-t from-nero via-nero/50 to-nero/10 -z-40"
            ></motion.div>
            <motion.div
                animate={controls}
                className="hidden md:block fixed w-[100vw] h-[100000000000vh] top-[99%] right-[0%] bg-nero -z-40"
            ></motion.div>
            <motion.div
                animate={controlsPhone}
                className="md:hidden fixed w-[100vw] h-[100vh] top-0 left-0 bg-gradient-to-t from-nero via-nero/50 to-nero/10 -z-40"
            ></motion.div>
            <motion.div
                animate={controlsPhone}
                className="md:hidden fixed w-[100vw] h-[100000000vh] top-[99%] left-0 bg-nero -z-40"
            ></motion.div>

            <div className="md:px-[10%] px-4">
                <motion.div
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className=" w-[100%] fixed left-0 top-0 -z-50 h-[100vh] text-bianco"
                >
                    <img
                        className="h-[100vh] w-screen object-cover"
                        src={servizio.bg}
                        alt=""
                    />
                </motion.div>
                <div className="h-[100vh] flex flex-col justify-around md:py-[10%] py-[30%] gap-[10%]">
                    <h1
                        id="title"
                        className="text-bianco text-center  md:text-left text-4xl md:text-6xl font-bold uppercase "
                    >
                        {servizio.titolo}
                    </h1>
                    <div className="bg-azzurro p-8 rounded-xl text-bianco md:max-w-[50%] self-end">
                        <p className="md:text-4xl text-xl font-semibold">
                            {servizio.descr1}
                        </p>
                        <p className="md:text-xl text-sm mt-5 ">
                            {servizio.descr2}
                        </p>
                    </div>

                    <div className="flex h-full flex-col items-center justify-end text-bianco  ">
                        <div className="animate-scroll">
                            <ArrowUp w="18" />
                        </div>
                        <p className="uppercase">i nostri progetti</p>
                    </div>
                </div>
            </div>
            {loaded && (
                <div className="md:px-44 md:mt-[40vh] mt-[20vh] relative px-4">
                    <div className="absolute md:left-0 right-0 rotate-180 md:rotate-0 translate-x-1/2 md:-translate-y-20 opacity-30">
                        <img className="w-[50vw]" src={Sfumatura1} alt="" />
                    </div>
                    <div className="absolute right-0 bottom-0  translate-x-1/2 translate-y-96 opacity-30">
                        <img className="w-[50vw]" src={Sfumatura2} alt="" />
                    </div>
                    {servizio.contents.map((content, i) => {
                        return (
                            <div key={i} className="">
                                {servizio.hasCategories ? (
                                    <div className="">
                                        <h1 className="text-bianco mt-40 md:text-6xl text-5xl font-bold uppercase">
                                            {content.category}
                                        </h1>

                                        <div
                                            className={`mt-7 grid ${
                                                content.isVertical &&
                                                content.isVideo
                                                    ? "md:grid-cols-4 p-12"
                                                    : !content.isVertical &&
                                                      content.isVideo
                                                    ? "md:grid-cols-2 p-12"
                                                    : "grid-cols-2 md:grid-cols-4 p-6"
                                            } gap-16 justify-items-center place-content-around  md:p-0`}
                                        >
                                            {content.items.map((item, j) => {
                                                if (content.isVideo) {
                                                    return (
                                                        <VideoPlayer
                                                            key={j}
                                                            src={item.url}
                                                            client={item.client}
                                                            isVertical={
                                                                content.isVertical
                                                            }
                                                        />
                                                    );
                                                }
                                                if (!content.isVideo) {
                                                    return (
                                                        <div className="w-full">
                                                            <AsyncImage
                                                                onClick={() => {
                                                                    setIsOpen(
                                                                        true
                                                                    );
                                                                    setIndice(
                                                                        j
                                                                    );
                                                                }}
                                                                src={item.url}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                }}
                                                                loader={
                                                                    <span className="bg-grigio"></span>
                                                                }
                                                                className=" cursor-pointer w-full aspect-square p-8 text-bianco bg-cover bg-center"
                                                            />

                                                            <SpringModal
                                                                isOpen={isOpen}
                                                                setIsOpen={
                                                                    setIsOpen
                                                                }
                                                                foto={
                                                                    content.items
                                                                }
                                                                indice={indice}
                                                                setIndice={
                                                                    setIndice
                                                                }
                                                                url={
                                                                    content
                                                                        .items[
                                                                        indice
                                                                    ].url
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <h2
                                            className={`${
                                                content.isVideo
                                                    ? "hidden"
                                                    : "block"
                                            } text-bianco md:text-3xl text-2xl mt-24`}
                                        >
                                            Galleria foto
                                        </h2>
                                        <div
                                            className={`mt-7 grid ${
                                                content.isVertical &&
                                                content.isVideo
                                                    ? "md:grid-cols-4 p-12"
                                                    : !content.isVertical &&
                                                      content.isVideo
                                                    ? "md:grid-cols-2 p-12"
                                                    : "grid-cols-2 md:grid-cols-4 p-6"
                                            } gap-16 justify-items-center place-content-around p-12 md:p-0`}
                                        >
                                            {content.items.map((item, j) => {
                                                if (content.isVideo) {
                                                    return (
                                                        <VideoPlayer
                                                            key={j}
                                                            src={item.url}
                                                            client={item.client}
                                                            isVertical={
                                                                content.isVertical
                                                            }
                                                        />
                                                    );
                                                }
                                                if (!content.isVideo) {
                                                    return (
                                                        <div className="w-full">
                                                            <AsyncImage
                                                                onClick={() => {
                                                                    setIsOpen(
                                                                        true
                                                                    );
                                                                    setIndice(
                                                                        j
                                                                    );
                                                                }}
                                                                src={item.url}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                }}
                                                                loader={
                                                                    <span className="bg-grigio"></span>
                                                                }
                                                                className=" cursor-pointer w-full aspect-square p-8 text-bianco bg-cover bg-center"
                                                            />

                                                            <SpringModal
                                                                isOpen={isOpen}
                                                                setIsOpen={
                                                                    setIsOpen
                                                                }
                                                                foto={
                                                                    content.items
                                                                }
                                                                indice={indice}
                                                                setIndice={
                                                                    setIndice
                                                                }
                                                                url={
                                                                    content
                                                                        .items[
                                                                        indice
                                                                    ].url
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
            <div>
                <div className="md:px-44 px-4">
                    <h1 className="text-bianco mt-40 md:text-6xl text-5xl font-bold uppercase">
                        cos'altro facciamo?
                    </h1>
                    <h2 className="text-bianco md:text-3xl text-2xl font-light">
                        I nostri altri servizi
                    </h2>
                </div>
                <div
                    className="slider-container mt-7 mb-36"
                    onMouseEnter={() => setShowArrows(1)}
                    onMouseLeave={() => /*setShowArrows(0)*/ ""}
                >
                    <div className="md:block hidden">
                        <Slider {...settings}>
                            {servizi.map((item, i) => {
                                if (item.id !== slug) {
                                    return (
                                        <div className="px-4" key={item.key}>
                                            <div
                                                onClick={() => {
                                                    if (!isDragging) {
                                                        window.location.href =
                                                            "/servizi/" +
                                                            item.id;
                                                    }
                                                }}
                                                className="rounded-lg h-64 bg-cover bg-center bg-no-repeat duration-300 transition-all cursor-pointer"
                                                style={{
                                                    backgroundImage: `url(${item.bg})`,
                                                }}
                                            >
                                                <div className="bg-gradient-to-r rounded-lg from-nero/60 via-nero/40 p-6 h-64 w-[90%]">
                                                    <h3 className="text-bianco text-2xl">
                                                        {item.titolo}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </Slider>
                    </div>
                    <div className="md:hidden">
                        <Slider {...settingsPhone}>
                            {servizi.map((item) => {
                                if (item.id !== slug) {
                                    return (
                                        <div className="px-4" key={item.key}>
                                            <div
                                                onClick={() => {
                                                    window.location.href =
                                                        "/servizi/" + item.id;
                                                }}
                                                className="rounded-lg h-64 bg-cover bg-center bg-no-repeat duration-300 transition-all cursor-pointer"
                                                style={{
                                                    backgroundImage: `url(${item.bg})`,
                                                }}
                                            >
                                                <div className="bg-gradient-to-r rounded-lg from-nero/60 via-nero/40 p-6 h-64 w-[90%]">
                                                    <h3 className="text-bianco text-2xl">
                                                        {item.titolo}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </Slider>
                    </div>
                </div>
                <div className="mb-36">
                    <Contattaci />
                </div>
            </div>
        </div>
    );
}

export default Servizi;
