import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import "animate.css";
import ChimeraTypo from "../../assets/SVG/ChimeraTypo";
import LastProject from "../../Components/Atoms/LastProject/LastProject";
import Servizio from "../../Components/Atoms/Servizio/Servizio";
import Sfumatura1 from "../../assets/sfumature/1.png";
import Sfumatura2 from "../../assets/sfumature/2.png";
import ArrowUp from "../../assets/SVG/ArrowUp";
import Contattaci from "../../Components/Molecules/Contattaci/Contattaci";
import ServizioLargo from "../../Components/Atoms/Servizio/ServizioLargo";
import ReactPageScroller, { SectionContainer } from "react-page-scroller";
import Footer from "../../Components/Molecules/Footer/Footer";
import { Player } from "@lottiefiles/react-lottie-player";
import { motion } from "framer-motion";
import images1 from "../../assets/perchi/LOGHI COLORATI/images1";
import images2 from "../../assets/perchi/LOGHI COLORATI/images2";
import images3 from "../../assets/perchi/LOGHI COLORATI/images3";
import CTA from "../../Components/Atoms/Bottoni/CTA";

gsap.registerPlugin(ScrollTrigger);

function Home() {
    var lastPage = 0;
    var currentNumber = 0;
    const isMobile = window.innerWidth < 1025;
    const playerRef = useRef(null);
    const mobile_playerRef = useRef(null);
    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [page, setPage] = useState(0);

    const handlePageChange = (number) => {
        console.log(number);
        //setPage(number)
        currentNumber = number;

        if (lastPage === 0 && number === 1) {
            if (!isMobile) {
                gsap.fromTo(
                    "#pittogramma",
                    { scale: 1, rotate: 6 },
                    {
                        scale: 0.8,
                        rotate: 0,
                        duration: 0.8,
                        delay: 0.2,
                        ease: "power3.out",
                    }
                );
            } else {
                gsap.fromTo(
                    "#pittogramma",
                    { scale: 1, rotate: 6 },
                    {
                        scale: 0.5,
                        translateY: "-50%",
                        translateX: "-50%",
                        rotate: 0,
                        duration: 0.8,
                        delay: 0.2,
                        ease: "power3.out",
                    }
                );
            }
            gsap.fromTo(
                "#sfumatura1",
                { opacity: 0.3 },
                {
                    opacity: 0,
                    duration: 1.5,
                    ease: "power3.out",
                }
            );
            gsap.fromTo(
                "#pittogramma_interno",
                { fillOpacity: 0 },
                {
                    fillOpacity: 1,
                    duration: 0.8,
                    delay: 0.2,
                    ease: "power3.out",
                }
            );
        }
        if (number === 0 && lastPage === 1) {
            gsap.fromTo(
                "#sfumatura1",
                { opacity: 0 },
                {
                    opacity: 0.3,
                    duration: 1.5,
                    ease: "power3.out",
                }
            );

            gsap.fromTo(
                "#pittogramma",
                {},
                {
                    scale: 1,
                    rotate: 6,
                    duration: 0.8,
                    delay: 0.2,
                    ease: "power3.out",
                }
            );
            gsap.fromTo(
                "#pittogramma_interno",
                { fillOpacity: 1 },
                {
                    fillOpacity: 0,
                    duration: 0.8,
                    delay: 0.2,
                    ease: "power3.out",
                }
            );
            gsap.fromTo(
                "#pittogramma",
                {},
                {
                    left: "50%",
                    duration: 0.8,
                    delay: 0.2,
                    ease: "power3.out",
                }
            );
            gsap.fromTo(
                "#first_text",
                {},
                { translateX: "-100%", duration: 1 }
            );
        }
        if (lastPage === 1 && number === 2) {
            if (isMobile) {
                gsap.fromTo(
                    "#pittogramma",
                    {},
                    {
                        scale: 0,
                        duration: 0.8,
                        ease: "power3.out",
                    }
                );
            } else {
                gsap.fromTo(
                    "#pittogramma",
                    {},
                    {
                        left: "85%",
                        duration: 0.8,
                        ease: "power3.out",
                        delay:0.15
                    }
                );
            }
            gsap.fromTo(
                "#first_text",
                {  translateX: "-100%" },
                {  translateX: 0, delay:0.37, duration: 1 }
            );
            gsap.fromTo(
                "#sfumatura",
                { rotate: 180, opacity: 0, scale: 1 },
                {
                    scale: isMobile ? 1.5 : 1,
                    rotate: 360,
                    opacity: 0.5,
                    duration: 0.8,
                }
            );
        }
        if (number === 1 && lastPage === 2) {
            if (isMobile) {
                gsap.fromTo(
                    "#pittogramma",
                    {},
                    {
                        scale: 0.5,
                        duration: 0.8,
                        ease: "power3.out",
                    }
                );
            } else {
                gsap.fromTo(
                    "#pittogramma",
                    {},
                    {
                        left: "50%",
                        duration: 0.8,
                        delay: 0.2,
                        ease: "power3.out",
                    }
                );
                gsap.fromTo(
                    "#first_text",
                    {  translateX: 0 },
                    {  translateX: "-100%", delay:0, duration: 0.5 }
                );
            }

            gsap.fromTo(
                "#first_text",
                { left: isMobile ? "5%" : "10%" },
                { left: isMobile ? "-200%" : "-100%", duration: 1 }
            );
            gsap.fromTo(
                "#sfumatura",
                { rotate: 0, opacity: 0.5 },
                {
                    rotate: 180,
                    opacity: 0,
                    duration: 0.8,
                }
            );
        }
        if (lastPage === 2 && number === 3) {
            gsap.fromTo(
                "#first_text",
                {  translateX: 0 },
                {  translateX: "-100%", delay:0, duration: 0.4 }
            );

            gsap.fromTo(
                "#pittogramma",
                {},
                {
                    left: "-40%",
                    duration: 2.5,
                    ease: "power3.out",
                }
            );

            gsap.fromTo(
                "#servizi",
                { x: "100%" },
                { x: "0%", duration: 1, delay: 0.8, ease: "power3.out" }
            );
            gsap.fromTo(
                "#div-sfumatura",
                {},
                {
                    top: "45%",
                    left: "10vw",
                    duration: 0.8,
                }
            );
            gsap.fromTo(
                "#sfumatura",
                { rotate: 0 },
                { rotate: 180, duration: 0.8 }
            );
            gsap.fromTo(
                "#sfumatura2",
                { right: "-2500px" },
                { right: "-500px", duration: 0.8 }
            );
        }
        if (number > 3) {
            gsap.fromTo(
                "#first_text",
                {},
                {
                    left: isMobile ? "-200%" : "-100%",
                    duration: isMobile ? 2 : 1,
                }
            );
        }

        if (lastPage === 3 && number === 2) {
            gsap.fromTo(
                "#servizi",
                { x: "0%" },
                { x: "100%", duration: 0.8, delay: 0, ease: "power3.out" }
            );
            gsap.fromTo(
                "#pittogramma",
                {},
                {
                    left: "90%",
                    duration: 1.5,
                    delay: 0.8,
                    ease: "power3.out",
                }
            );
            gsap.fromTo(
                "#first_text",
                {  translateX: "-100%" },
                {  translateX: 0, delay:0.8, duration: 1 }
            );
            gsap.fromTo(
                "#div-sfumatura",
                {},
                {
                    left: "90vw",
                    duration: 0.8,
                }
            );
            gsap.fromTo(
                "#sfumatura",
                { rotate: 180 },
                { rotate: 0, duration: 0.8 }
            );
        }
        if (lastPage === 4 && number === 3) {
            gsap.fromTo("#div-sfumatura", {}, { top: "45%", duration: 0.8 });
            gsap.fromTo(
                "#sfumatura",
                { rotate: 360 },
                { rotate: 180, duration: 1 }
            );
        }
        if (lastPage === 3 && number === 4) {
            gsap.fromTo(
                "#div-sfumatura",
                {},
                {
                    top: "-30%",
                    duration: 0.8,
                }
            );
            gsap.fromTo("#sfumatura", {}, { rotate: 360, duration: 0.8 });
        }
        if (lastPage === 5 && number === 4) {
            gsap.fromTo(
                "#italia",
                {},
                {
                    translateX: "100%",
                    opacity: 0,
                    delay: 0.01,
                    duration: 1.2,
                }
            );
            gsap.fromTo(
                "#sfumatura",
                { rotate: 180, opacity: 0 },
                {
                    rotate: 360,
                    opacity: 0.5,
                    duration: 0.8,
                }
            );
        }

        if (lastPage === 4 && number === 5) {
            gsap.fromTo(
                "#italia",
                { translateX: "100%" },
                {
                    translateX: "0%",
                    opacity: 1,
                    delay: 0,
                    duration: 0.8,
                }
            );

            gsap.fromTo(
                "#sfumatura",
                { rotate: 0, opacity: 0.5 },
                {
                    rotate: 180,
                    opacity: 0,
                    duration: 0.8,
                }
            );
        }
        if (number === 5) {
            if (playerRef.current) {
                playerRef.current.stop(); // Ferma l'animazione se è in corso
                playerRef.current.play(); // Avvia l'animazione
            }

            setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.pause(); // Ferma l'animazione dopo 8 secondi
                }
            }, 8000);
        } else {
            if (playerRef.current) {
                playerRef.current.pause();
            }
        }

        if (lastPage === 5 && number === 6) {
            gsap.fromTo(
                "#sfumatura2",
                {},
                {
                    right: "-2500px",
                }
            );
            gsap.fromTo(
                "#perchi",
                { opacity: 0 },
                { opacity: 1, delay: 0, duration: 1 }
            );
            gsap.fromTo(
                "#img-perchi",
                { scale: 0.5, rotate: 0 },
                { scale: 1, rotate: 360, delay: 0.5, duration: 1 }
            );
        }
        if (number === 5 && lastPage === 6) {
            gsap.fromTo(
                "#img-perchi",
                { scale: 1 },
                { scale: 0.5, duration: 1 }
            );
            gsap.fromTo("#perchi", { opacity: 1 }, { opacity: 0, duration: 1 });
            gsap.fromTo("#sfumatura2", {}, { right: "-500px", duration: 0.8 });
        }

        if (number === 8 && lastPage === 7) {
            gsap.fromTo(
                "#contatti_home",
                {
                    height: "100vh",
                },
                {
                    height: "80vh",
                    duration: 1,
                }
            );
            gsap.fromTo(
                "#footer_home",
                { translateY: "100%" },
                { translateY: 0, duration: 1 }
            );
        }
        if (number === 7 && lastPage === 8) {
            gsap.fromTo(
                "#contatti_home",
                {
                    height: "80vh",
                },
                {
                    height: "100vh",
                    duration: 1,
                }
            );
            gsap.fromTo(
                "#footer_home",
                { translateY: 0 },
                { translateY: "100%", duration: 1 }
            );
        }

        lastPage = number;
    };

    useEffect(() => {
        const pittogrammaTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: "#sezione_pittogramma_mobile",
                start: "top center",
                end: "bottom center",
                scrub: true,
                pin: false,
                toggleActions: "play none none reverse",
                onLeave: () => {
                    gsap.to("#pittogramma_mobile", {
                        left: "-150%", // Sposta il pittogramma completamente a sinistra
                        duration: 1,
                        ease: "easeout",
                    });
                    gsap.to("#first_text_mobile", {
                        left: "50%", // Fai comparire il testo centrale
                        duration: 1,
                        ease: "power3.out",
                        opacity: 1,
                    });
                },
                onEnterBack: () => {
                    gsap.to("#pittogramma_mobile", {
                        left: "50%", // Riporta il pittogramma alla posizione iniziale
                        duration: 1,
                        ease: "power3.out",
                    });
                    gsap.to("#first_text_mobile", {
                        left: "-50%", // Nasconde nuovamente il testo
                        duration: 1,
                        ease: "power3.out",
                        opacity: 0,
                    });
                },
            },
        });

        pittogrammaTimeline.fromTo(
            "#pittogramma_mobile",
            { scale: 1, rotate: 6 },
            {
                scale: 0.5,
                translateY: "-50%",
                translateX: "-50%",
                rotate: 0,
                duration: 1.5,
                ease: "power3.out",
            }
        );

        pittogrammaTimeline.fromTo(
            "#pittogramma_interno_mobile",
            { fillOpacity: 0 },
            {
                duration: 1.5,
                fillOpacity: 1,
                ease: "power3.out",
            },
            "<"
        );

        pittogrammaTimeline.fromTo(
            "#chimera_mobile",
            { scale: 1 },
            {
                scale: 0,
                duration: 1.5,
                ease: "power3.out",
                immediateRender: false,
            },
            "<"
        );

        pittogrammaTimeline.fromTo(
            "#scroll_mobile",
            { scale: 1 },
            {
                scale: 0,
                duration: 1.5,
                ease: "power3.out",
                immediateRender: false,
            },
            "<"
        );

        pittogrammaTimeline.fromTo(
            "#first_text_mobile",
            { left: "-50%", opacity: 0 },
            {
                left: "50%",
                opacity: 1,
                duration: 1.5,
                ease: "power3.out",
            },
            "-=0.5" // Leggero overlap per l'animazione fluida
        );

        // Animazione per #perchi_mobile
        gsap.fromTo(
            "#perchi_mobile",
            { opacity: 0 },
            {
                opacity: 1,
                duration: 1.5,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: "#perchi_mobile",
                    start: "top 80%", // Inizia l'animazione quando il trigger è 80% nel viewport
                    toggleActions: "play none none none",
                },
            }
        );
    }, []);

    // Animazione per Italia da telefono
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting); // Aggiorna lo stato quando il componente è visibile
            },
            {
                threshold: 0.1, // Percentuale di visibilità per attivare il callback
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current); // Inizia ad osservare il contenitore
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current); // Pulisci l'osservatore quando il componente si smonta
            }
        };
    }, []);
    useEffect(() => {
        console.log("isVisible", isVisible);

        if (mobile_playerRef.current) {
            if (isVisible) {
                mobile_playerRef.current.stop();
                mobile_playerRef.current.play(); // Avvia l'animazione quando il componente è visibile
            } else {
                mobile_playerRef.current.stop(); // Ferma l'animazione quando il componente non è visibile
            }
        }
    }, [isVisible]);

    return (
        <div className=" relative">
            {!isMobile && (
                <div className="md:block hidden relative z-50">
                    <svg
                        id="pittogramma"
                        xmlns="http://www.w3.org/2000/svg"
                        data-name="Livello 1"
                        viewBox="0 0 794.41 660.33"
                        className=" md:h-[90vh] h-[70vh] z-10 rotate-6 fixed-element fixed top-[55%] left-1/2 md:-translate-x-1/2 -translate-x-[65%] md:-translate-y-1/2 -translate-y-[60%]"
                    >
                        <path
                            id="pittogramma_interno"
                            d="M531.31 187.51c-.55-.17-1.08-.38-1.63-.54L377.5 17.21l57.19 170.4-49 .06L238.12 25l55.49 165.1c-4.29.58-8.32 1.17-11.91 1.69-49.28 7.14-96.83 26.7-136.43 57C85.79 294.17 44.91 363.28 33.73 437.31c5.12-14.31 20.12-25.45 31-31.74 37.07-21.43 63-24.49 95.55-23.12-32.65 20.17-55.37 54.13-66.74 90.79s-12.15 75.91-7.71 114c22-39.79 64.35-67.56 109.6-71.8-4.52 50.91 23.56 103.25 68.48 127.65a85.83 85.83 0 0177.92-99c56.48 38.21 116.35 62.29 192.62 59.21 64.1-2.58 144.71-51.89 144.71-116a151.77 151.77 0 01-209.43 26.73c-35.49-26.61-53.08-73.91-55.36-117-.06-1-.06-2.08-.09-3.13a196.8 196.8 0 008.79 9.67c23.66 24.31 59.75 39.07 85.4 43.82 43.11 8 66.84 3.72 93.48-7.68 30-12.87 42.23-32.75 48.4-59.79-2 4.62-10.22 11.8-16.68 15.81-33.75 21-60.3 15.55-63.71 17.54 22-12.86 31.51-40.86 35.89-66.73-8.66 21.21-27.9 36.61-48.77 43.55-38.62 12.86-103.64 2-133.56-24.77a82.88 82.88 0 01-6.77-6.88c-.72-3.5-.31-7.81 1.65-13.15a151.79 151.79 0 01260.76-41.8l81.51-116.1c-.87-.06-117.24.01-229.36.12z"
                            className="cls-1"
                            stroke="#ffffff88"
                            fill="white"
                            fillOpacity={0}
                            strokeWidth="1"
                            strokeLinejoin="round"
                        ></path>
                    </svg>

                    <div className="fixed fixed-element z-[-10] top-0 left-0 translate-x-12 -translate-y-1/2 w-[200vw]">
                        <img
                            id="sfumatura1"
                            style={{ opacity: 0.3 }}
                            src={Sfumatura1}
                            alt=""
                            className="w-[250vw] opacity-30 rotate-[220deg]"
                        />
                    </div>
                    <div
                        id="sfumatura2"
                        className="fixed fixed-element z-[-10] -top-24 -right-[2500px] -translate-y-0 w-[90vw]"
                    >
                        <img
                            style={{ opacity: 0.3 }}
                            src={Sfumatura1}
                            alt=""
                            className="w-[100vw]   opacity-30 rotate-[150deg]"
                        />
                    </div>
                </div>
            )}
            <div
                id="div-sfumatura"
                className="fixed md:top-[45%] fixed-element -bottom-20  z-[-10] md:left-[90vw] -translate-x-[30%] w-full"
            >
                <img
                    id="sfumatura"
                    style={{ opacity: 0 }}
                    src={Sfumatura2}
                    className="w-[55vw]"
                    alt=""
                />
                 <div className="absolute right-0 -top-20 w-[100vw] opacity-[30%] translate-x-[20%]">
                                    <img
                                        src={Sfumatura1}
                                        className=" w-[200vw]  rotate-[-15deg]"
                                        alt=""
                                    />
                                </div> 
            </div>
            {isMobile ? (
                <div className="overflow-auto">
                    <div className="section w-full h-[90lvh] mb-[10dvh]">
                        <div id="trigger_logo" className="w-4 h-4 opacity-0 " />
                        <div className="fixed right-0 bottom-0 w-[200vw] translate-x-56 translate-y-56">
                            <img
                                src={Sfumatura1}
                                className="rotate-[210deg] w-[200vw] opacity-30"
                                alt=""
                            />
                        </div>
                        <div className="">
                            <div className="w-[100vw]  relative h-[100lvh]">
                                <div
                                    id="chimera_mobile"
                                    className="fixed top-[50lvh] right-1/2 -translate-y-1/2 translate-x-1/2"
                                >
                                    {window.innerWidth > 1024 ? (
                                        <ChimeraTypo w={1000} />
                                    ) : window.innerWidth > 700 ? (
                                        <ChimeraTypo w={700} />
                                    ) : window.innerWidth > 400 ? (
                                        <ChimeraTypo w={400} />
                                    ) : window.innerWidth > 350 ? (
                                        <ChimeraTypo w={300} />
                                    ) : (
                                        <ChimeraTypo w={250} />
                                    )}
                                </div>

                                <div
                                    id="scroll_mobile"
                                    className=" flex opacity-100 flex-col items-center fixed left-1/2 -translate-x-1/2 top-[75lvh] z-0"
                                >
                                    <div className="animate-scroll">
                                        <ArrowUp />
                                    </div>
                                    <p className="uppercase text-bianco text-3xl">
                                        scroll
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="sezione_pittogramma_mobile"
                        className="section h-[100lvh]"
                    >
                        <svg
                            id="pittogramma_mobile"
                            xmlns="http://www.w3.org/2000/svg"
                            data-name="Livello 1"
                            viewBox="0 0 794.41 660.33"
                            className=" h-[70lvh] rotate-6 fixed top-[55lvh] left-1/2 md:-translate-x-1/2 -translate-x-[65%] md:-translate-y-1/2 -translate-y-[60%]"
                        >
                            <path
                                id="pittogramma_interno_mobile"
                                d="M531.31 187.51c-.55-.17-1.08-.38-1.63-.54L377.5 17.21l57.19 170.4-49 .06L238.12 25l55.49 165.1c-4.29.58-8.32 1.17-11.91 1.69-49.28 7.14-96.83 26.7-136.43 57C85.79 294.17 44.91 363.28 33.73 437.31c5.12-14.31 20.12-25.45 31-31.74 37.07-21.43 63-24.49 95.55-23.12-32.65 20.17-55.37 54.13-66.74 90.79s-12.15 75.91-7.71 114c22-39.79 64.35-67.56 109.6-71.8-4.52 50.91 23.56 103.25 68.48 127.65a85.83 85.83 0 0177.92-99c56.48 38.21 116.35 62.29 192.62 59.21 64.1-2.58 144.71-51.89 144.71-116a151.77 151.77 0 01-209.43 26.73c-35.49-26.61-53.08-73.91-55.36-117-.06-1-.06-2.08-.09-3.13a196.8 196.8 0 008.79 9.67c23.66 24.31 59.75 39.07 85.4 43.82 43.11 8 66.84 3.72 93.48-7.68 30-12.87 42.23-32.75 48.4-59.79-2 4.62-10.22 11.8-16.68 15.81-33.75 21-60.3 15.55-63.71 17.54 22-12.86 31.51-40.86 35.89-66.73-8.66 21.21-27.9 36.61-48.77 43.55-38.62 12.86-103.64 2-133.56-24.77a82.88 82.88 0 01-6.77-6.88c-.72-3.5-.31-7.81 1.65-13.15a151.79 151.79 0 01260.76-41.8l81.51-116.1c-.87-.06-117.24.01-229.36.12z"
                                className="cls-1"
                                stroke="white"
                                fill="white"
                                fillOpacity={0}
                                strokeWidth="1"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    </div>
                    <div className="section h-[70lvh] flex items-end justify-center">
                        <div
                            id="first_text_mobil"
                            className="uppercase pl-10 text-bianco md:text-5xl text-4xl flex flex-col gap-3"
                        >
                            <p className="font-thin">se cerchi una</p>
                            <p className="font-bold">
                                casa di <br className="md:hidden" /> produzione
                            </p>
                            <p className="font-thin">
                                è il momento di cambiare
                            </p>
                            <div className="mt-20">
                                <CTA titolo="SCOPRICI" link="/chisiamo" />
                            </div>
                        </div>
                    </div>

                    {isMobile && (
                        <div
                            id="servizi"
                            className="section pt-44 mt-14 w-full  flex  flex-col items-center text-bianco "
                        >
                            <h2 className="md:text-5xl text-3xl text-bianco font-bold uppercase mb-10">
                                cosa facciamo
                            </h2>
                            <div className="flex lg:flex-row flex-col justify-center gap-5 xl:w-[60%] w-[75%] mt-2">
                                <ServizioLargo
                                    redirect="livecoverage"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/live++coverage.jpg"
                                    }
                                    titolo="live coverage"
                                    descr1="Tutto ciò che è successo oggi è già vecchio. "
                                    descr2="Ad ogni vostro evento, noi saremo lì, registreremo i contenuti e li pubblicheremo, per un'esperienza live."
                                />
                                <Servizio
                                    redirect="videofotoeventi"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/foto+e+video.jpg"
                                    }
                                    titolo="video/foto eventi"
                                    descr="Il vostro verrà coperto a 360 con i nostri fotografi e videomaker!"
                                />
                            </div>
                            <div className="flex lg:flex-row flex-col justify-center gap-5 xl:w-[60%] w-[75%] mt-5">
                                <Servizio
                                    redirect="ecommerce"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/e-commerce.jpg"
                                    }
                                    titolo="foto e-commerce"
                                    descr="Tutto ciò che si può fotografare può comunicare… e noi lo sappiamo fare."
                                />
                                <ServizioLargo
                                    redirect="contentcreation"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/CONTENT+CREATION.jpg"
                                    }
                                    titolo="content creation"
                                    descr1="Avete delle idee per raccontare la vostra realtà ma non sapere come fare? ci pensiamo noi"
                                    descr2="Ogni tipo di produzione che avete in mente noi la sappiamo fare!"
                                />
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <div className="pt-44 w-full flex flex-col items-center text-bianco">
                            <div className="flex lg:flex-row flex-col justify-center gap-5 xl:w-[60%] w-[75%] mt-5">
                                <ServizioLargo
                                    redirect="cgi"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/cgi+.jpg"
                                    }
                                    titolo="cgi"
                                    descr1="Trasforma l'immaginazione in realtà."
                                    descr2="Utilizzeremo le nostre competenze e software 3D per comunicare al meglio il tuo prodotto o evento."
                                />
                                <Servizio
                                    redirect="dronefpv"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/drone.jpg"
                                    }
                                    titolo="drone fpv"
                                    descr="Dinamicità, agilità, sicurezza e massima performance da tutte le prospettive che desideri, i nostri droni saranno i tuoi occhi per creare qualcosa di unico."
                                />
                            </div>
                            <div className="flex lg:flex-row flex-col justify-center gap-5 xl:w-[60%] w-[75%] mt-5">
                                <Servizio
                                    redirect="editoriali"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/editoriali2.jpg"
                                    }
                                    titolo="editoriali"
                                    descr="Ti aiuteremo a raccontare una storia visiva che catturi l'attenzione del tuo pubblico e metta in risalto le caratteristiche uniche dei tuoi prodotti."
                                />
                                <ServizioLargo
                                    redirect="minicommercial"
                                    backgroundImage={
                                        "https://chimerastudio.s3.eu-north-1.amazonaws.com/mini+commercial.jpg"
                                    }
                                    titolo="mini commercial"
                                    descr1="Volete qualcosa di piu “cinematografico”? Facciamo anche questo!"
                                />
                            </div>
                        </div>
                    )}
                    <div className="relative  my-32">
                        <div
                            id="italia"
                            className="grid w-full  text-bianco   grid-cols-1 col-span-2"
                        >
                            <div
                                ref={containerRef}
                                className=" md:h-auto md:w-auto relative "
                            >
                                <h1 className="uppercase pl-5 block md:hidden font-bold md:text-6xl text-4xl">
                                    <span className="font-light md:text-5xl text-3xl">
                                        il nostro
                                    </span>{" "}
                                    metodo
                                </h1>
                                <Player
                                    ref={mobile_playerRef}
                                    src={require("../../assets/Animazione sito_ITALY.json")}
                                    className=" my-16 md:my-0 w-full md:h-[70lvh] h-[40lvh]"
                                ></Player>
                            </div>
                            <div className=" flex justify-start flex-col items-start lg:pl-20 pl-5">
                                <h1 className="uppercase md:block hidden font-semibold text-7xl">
                                    <span className="font-light text-5xl">
                                        il nostro
                                    </span>{" "}
                                    <br /> metodo
                                </h1>
                                <div className="">
                                    <p className="md:mt-10 font-thin md:text-3xl text-2xl">
                                        <span className="md:text-6xl text-4xl font-semibold">
                                            300+
                                        </span>
                                        <span className="font-semibold">
                                            Artisti
                                        </span>{" "}
                                        operativi in tutta{" "}
                                        <br className="md:block hidden" />{" "}
                                        Italia e più di{" "}
                                        <span className="font-semibold">
                                            300+ progetti
                                        </span>{" "}
                                        alle spalle
                                    </p>
                                </div>
                                <div className=" md:mt-20 mt-5 w-full flex justify-start">
                                    <CTA titolo="SCOPRICI" link="/chisiamo" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="perchi_mobile"
                        className="section w-full h-[100lvh] relative z-0 opacity-100 bg-azzurro flex justify-center items-center  text-bianco"
                    >
                        <div
                            id="img-perchi"
                            className={`absolute w-full rotate-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-0 flex justify-center items-center`}
                        >
                            <img
                                className={`filter  brightness-0 invert opacity-30 `}
                                src={Sfumatura2}
                                alt=""
                            />
                        </div>
                        <div
                            id="perchi_text"
                            className="flex scale-10 w-full flex-col justify-center items-center"
                        >
                            <p className="uppercase text-center md:text-8xl text-5xl font-extrabold ">
                                per chi
                            </p>
                            <p className="uppercase text-center text-4xl mt-4 font-normal ">
                                abbiamo lavorato?
                            </p>
                            <div className="flex flex-col mt-20 justify-center">
                                <div className="flex items-center mb-3 ">
                                    <TestimonialList list={images1} />
                                    <TestimonialList list={images1} />
                                    <TestimonialList list={images1} />
                                </div>
                                <div className="flex items-center mb-3 ">
                                    <TestimonialList list={images2} reverse />
                                    <TestimonialList list={images2} reverse />
                                    <TestimonialList list={images2} reverse />
                                </div>
                                <div className="flex items-center ">
                                    <TestimonialList list={images3} />
                                    <TestimonialList list={images3} />
                                    <TestimonialList list={images3} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        id="contatti"
                        className="section  w-full  relative md:pt-16 mt-20 left-0"
                    >
                        <div className="lg:h-[65dvh] pb-10 pt-[5%]">
                            <Contattaci />
                        </div>
                        {!isMobile && (
                            <div className="absolute  bottom-0">
                                <Footer />
                            </div>
                        )}
                    </div>
                    {isMobile && (
                        <div className="">
                            <div className="   ">
                                <Footer />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="z-[0] relative">
                    {" "}
                    <ReactPageScroller
                        animationTimer={800}
                        animationTimerBuffer={300}
                        renderAllPagesOnFirstRender={true}
                        transitionTimingFunction={"ease-in-out"}
                        onBeforePageScroll={handlePageChange}
                        className="z-50"
                        //customPageNumber={page}
                    >
                        <div className="section w-full z-10 h-[100vh]">
                            <div
                                id="trigger_logo"
                                className="w-4 h-4 opacity-0 "
                            />
                            <div className="">
                                <div className="w-[100vw]  relative h-[100dvh]">
                                    <div className="fixed top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2">
                                        {window.innerWidth > 1024 ? (
                                            <ChimeraTypo w={1000} />
                                        ) : window.innerWidth > 700 ? (
                                            <ChimeraTypo w={700} />
                                        ) : window.innerWidth > 400 ? (
                                            <ChimeraTypo w={400} />
                                        ) : window.innerWidth > 350 ? (
                                            <ChimeraTypo w={300} />
                                        ) : (
                                            <ChimeraTypo w={250} />
                                        )}
                                    </div>

                                    <div
                                        id="scroll"
                                        className=" flex opacity-100 flex-col items-center absolute left-1/2 -translate-x-1/2 top-[85%] z-0"
                                    >
                                        <div className="animate-scroll">
                                            <ArrowUp />
                                        </div>
                                        <p className="uppercase text-bianco text-3xl">
                                            scroll
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section h-[100dvh]"></div>
                        <div
                            id="first_text"
                            className="uppercase w-full h-[100lvh] translate-y-[40vh] lg:-translate-x-full pl-20 text-bianco md:text-5xl text-4xl flex flex-col gap-3"
                        >
                            <div >
                                <p className="font-thin">se cerchi una</p>
                                <p className="font-bold md:text-7xl text-5xl">
                                    casa di <br className="" /> produzione
                                </p>
                                <p className="font-thin">
                                    è il momento di cambiare
                                </p>
                                <div className="mt-10 z-[999] relative cursor-pointer">
                                    <CTA titolo="SCOPRICI" link="/chisiamo" />
                                </div>
                               
                            </div>
                        </div>

                        {!isMobile && (
                            <div
                                id="servizi"
                                className="section pt-[15dvh]  w-full h-[100dvh] flex  flex-col items-center text-bianco translate-x-[100%]"
                            >
                                <h2 className="md:text-5xl text-3xl text-bianco font-bold uppercase mb-10">
                                    cosa facciamo
                                </h2>
                                <div className="flex md:flex-row flex-col justify-center gap-5 xl:w-[70%] w-[80%] mt-2">
                                    <ServizioLargo
                                        redirect="livecoverage"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/live++coverage.jpg"
                                        }
                                        titolo="live coverage"
                                        descr1="Tutto ciò che è successo oggi è già vecchio. "
                                        descr2="Ad ogni vostro evento, noi saremo lì, registreremo i contenuti e li pubblicheremo, per un'esperienza live."
                                    />
                                    <Servizio
                                        redirect="videofotoeventi"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/foto+e+video.jpg"
                                        }
                                        titolo="video/foto eventi"
                                        descr="Il vostro verrà coperto a 360 con i nostri fotografi e videomaker!"
                                    />
                                </div>
                                <div className="flex md:flex-row flex-col justify-center gap-5 xl:w-[70%] w-[80%] mt-5">
                                    <Servizio
                                        redirect="ecommerce"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/e-commerce.jpg"
                                        }
                                        titolo="foto e-commerce"
                                        descr="Tutto ciò che si può fotografare può comunicare… e noi lo sappiamo fare."
                                    />
                                    <ServizioLargo
                                        redirect="contentcreation"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/CONTENT+CREATION.jpg"
                                        }
                                        titolo="content creation"
                                        descr1="Avete delle idee per raccontare la vostra realtà ma non sapere come fare? ci pensiamo noi"
                                        descr2="Ogni tipo di produzione che avete in mente noi la sappiamo fare!"
                                    />
                                </div>
                            </div>
                        )}
                        {!isMobile && (
                            <div className="pt-[15dvh] w-full flex flex-col items-center text-bianco">
                                <div className="flex md:flex-row flex-col justify-center gap-5 xl:w-[70%] w-[80%] mt-5">
                                    <ServizioLargo
                                        redirect="cgi"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/cgi+.jpg"
                                        }
                                        titolo="cgi"
                                        descr1="Trasforma l'immaginazione in realtà."
                                        descr2="Utilizzeremo le nostre competenze e software 3D per comunicare al meglio il tuo prodotto o evento."
                                    />
                                    <Servizio
                                        redirect="dronefpv"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/drone.jpg"
                                        }
                                        titolo="drone fpv"
                                        descr="Dinamicità, agilità, sicurezza e massima performance da tutte le prospettive che desideri, i nostri droni saranno i tuoi occhi per creare qualcosa di unico."
                                    />
                                </div>
                                <div className="flex md:flex-row flex-col justify-center gap-5 xl:w-[70%] w-[80%] mt-5">
                                    <Servizio
                                        redirect="editoriali"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/editoriali2.jpg"
                                        }
                                        titolo="editoriali"
                                        descr="Ti aiuteremo a raccontare una storia visiva che catturi l'attenzione del tuo pubblico e metta in risalto le caratteristiche uniche dei tuoi prodotti."
                                    />
                                    <ServizioLargo
                                        redirect="minicommercial"
                                        backgroundImage={
                                            "https://chimerastudio.s3.eu-north-1.amazonaws.com/mini+commercial.jpg"
                                        }
                                        titolo="mini commercial"
                                        descr1="Volete qualcosa di piu “cinematografico”? Facciamo anche questo!"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="relative h-[100dvh]">
                            <div
                                id="italia"
                                className="grid w-full md:grid-cols-2 text-bianco absolute top-[25%] md:opacity-0 grid-cols-1 col-span-2"
                            >
                                <div className=" md:h-auto md:w-auto relative ">
                                    <h1 className="uppercase pl-5 block md:hidden font-bold md:text-6xl text-4xl">
                                        <span className="font-light md:text-5xl text-3xl">
                                            il nostro
                                        </span>{" "}
                                        metodo
                                    </h1>
                                    <Player
                                        key={`${lastPage}-${currentNumber}`}
                                        ref={playerRef}
                                        loop={false}
                                        src={require("../../assets/Animazione sito_ITALY.json")}
                                        className=" my-16 md:my-0 w-full md:h-[70vh] h-[40vh]"
                                    ></Player>
                                </div>
                                <div className="h-[70vh] flex justify-start flex-col items-start lg:pl-20 pl-5">
                                    <h1 className="uppercase md:block hidden font-semibold text-7xl">
                                        <span className="font-light text-5xl">
                                            il nostro
                                        </span>{" "}
                                        <br /> metodo
                                    </h1>
                                    <div className="">
                                        <p className="md:mt-10 font-thin md:text-3xl text-2xl">
                                            <span className="md:text-6xl text-4xl font-semibold">
                                                300+
                                            </span>
                                            <span className="font-semibold">
                                                Artisti
                                            </span>{" "}
                                            operativi in tutta{" "}
                                            <br className="md:block hidden" />{" "}
                                            Italia e più di{" "}
                                            <span className="font-semibold">
                                                300+ progetti
                                            </span>{" "}
                                            alle spalle
                                        </p>
                                    </div>
                                    <div className=" md:mt-20 mt-5 w-full flex justify-start">
                                        <CTA
                                            titolo="SCOPRICI"
                                            link="/chisiamo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            id="perchi"
                            className="section w-full h-[100dvh] relative z-0 opacity-0 bg-azzurro flex justify-center items-center  text-bianco"
                        >
                            <div
                                id="img-perchi"
                                className={`absolute w-full rotate-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-50 flex justify-center items-center`}
                            >
                                <img
                                    className={`filter  brightness-0 invert opacity-30 `}
                                    src={Sfumatura2}
                                    alt=""
                                />
                            </div>
                            <div
                                id="perchi_text"
                                className="flex h-[90lvh] scale-10 w-full flex-col justify-around items-center py-[3%]"
                            >
                                <div className="flex flex-col gap-5">
                                    <p className="uppercase text-center md:text-8xl text-5xl font-extrabold ">
                                        per chi
                                    </p>
                                    <p className="uppercase text-center text-4xl font-normal ">
                                        abbiamo lavorato?
                                    </p>
                                </div>

                                <div className="flex flex-col mt-0 justify-center">
                                    <div className="flex items-center mb-3 ">
                                        <TestimonialList list={images1} />
                                        <TestimonialList list={images1} />
                                        <TestimonialList list={images1} />
                                    </div>
                                    <div className="flex items-center mb-3 ">
                                        <TestimonialList
                                            list={images2}
                                            reverse
                                        />
                                        <TestimonialList
                                            list={images2}
                                            reverse
                                        />
                                        <TestimonialList
                                            list={images2}
                                            reverse
                                        />
                                    </div>
                                    <div className="flex items-center ">
                                        <TestimonialList list={images3} />
                                        <TestimonialList list={images3} />
                                        <TestimonialList list={images3} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SectionContainer height={1}></SectionContainer>
                        <div className="h-[100dvh] relative">
                            <div
                                id="contatti_home"
                                className="section w-full h-[100%] flex justify-center items-center relative"
                            >
                                <img
                                    src={Sfumatura1}
                                    alt=""
                                    className="absolute opacity-30 rotate-45 -left-[60vw]"
                                />
                                <img
                                    src={Sfumatura2}
                                    alt=""
                                    className="absolute opacity-30 -rotate-12 -right-[40vw] top-[20vh]"
                                />
                                <Contattaci />{" "}
                            </div>
                            <div
                                id="footer_home"
                                className="absolute w-full translate-y-[100%] bottom-0"
                            >
                                <Footer />
                            </div>
                        </div>
                    </ReactPageScroller>
                </div>
            )}
        </div>
    );
}

export default Home;

const TestimonialList = ({ list, reverse = false, duration = 50 }) => {
    return (
        <motion.div
            initial={{ translateX: reverse ? "-100%" : "0%" }}
            animate={{ translateX: reverse ? "0%" : "-100%" }}
            transition={{ duration, repeat: Infinity, ease: "linear" }}
            className="flex gap-4 px-2"
        >
            {list.map((t, index) => {
                return (
                    <div
                        key={index}
                        className="shrink-0 mx-10 grid grid-cols-[7rem,_1fr] rounded-lg overflow-hidden relative"
                    >
                        <img
                            src={t}
                            alt=""
                            className="w-full h-24 object-contain "
                        />
                        <span className="text-7xl absolute top-2 right-2 text-slate-700"></span>
                    </div>
                );
            })}
        </motion.div>
    );
};
