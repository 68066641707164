import React, { useEffect, useRef, useState } from "react";
import ArrowUp from "../../assets/SVG/ArrowUp";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Sfumatura1 from "../../assets/sfumature/1.png";
import Sfumatura2 from "../../assets/sfumature/2.png";
import Team from "../../Components/Molecules/Team/Team";
import Contattaci from "../../Components/Molecules/Contattaci/Contattaci";
import { Player } from "@lottiefiles/react-lottie-player";
import { motion } from "framer-motion";
import videoFile from "../../assets/CHIMERA - v.6.mp4";
import Footer from "../../Components/Molecules/Footer/Footer";
import ReactPageScroller, { SectionContainer } from "react-page-scroller";

function Chisiamo() {
    const isMobile = window.innerWidth < 1025;
    const [ita, setIta] = useState(isMobile ? true : false);
    const [isVisible, setIsVisible] = useState(false);
    gsap.registerPlugin(ScrollTrigger);

    const scrub = isMobile ? 0 : 0.8;
    var lastPage = 0;

    const playerRef = useRef(null);
    const mobile_playerRef = useRef(null);
    const containerRef = useRef(null);

    // Animazione per Italia da telefono
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting); // Aggiorna lo stato quando il componente è visibile
            },
            {
                threshold: 0.1, // Percentuale di visibilità per attivare il callback
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current); // Inizia ad osservare il contenitore
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current); // Pulisci l'osservatore quando il componente si smonta
            }
        };
    }, []);
    useEffect(() => {
        console.log("isVisible", isVisible);

        if (mobile_playerRef.current) {
            if (isVisible) {
                mobile_playerRef.current.stop();
                mobile_playerRef.current.play(); // Avvia l'animazione quando il componente è visibile
            } else {
                mobile_playerRef.current.stop(); // Ferma l'animazione quando il componente non è visibile
            }
        }
    }, [isVisible]);

    const handlePageChange = (number) => {
        console.log(number);
        if (number === 0 && lastPage === 1) {
            gsap.fromTo(
                "#mockup",
                { translateX: "50%", translateY: "-50%" },
                {
                    translateX: "650%",
                    translateY: "100%",
                    duration: 2,
                    delay: 0,
                }
            );
            gsap.fromTo(
                "#vision_text",
                {
                    translateX: "0%",
                },
                {
                    translateX: "-150%",
                    duration: 0.8,
                    delay: 0,
                }
            );
        }
        if (number === 1 && lastPage === 0) {
            gsap.fromTo(
                "#vision_text",
                {
                    translateX: "-150%",
                },
                {
                    translateX: "0%",
                    duration: 1,
                    delay: 0,
                }
            );
            gsap.fromTo(
                "#mockup",
                { translateX: "650%", translateY: "100%" },
                {
                    translateX: "50%",
                    translateY: "-50%",
                    duration: 1,
                    delay: 0.2,
                }
            );
        }
        if (number === 2 && lastPage === 3) {
            gsap.fromTo(
                "#mockup",
                { translateY: "-250%" },
                { translateY: "-50%", duration: 1, delay: 0 }
            );
            gsap.fromTo(
                "#italia",
                { opacity: 1, translateX: 0, translateY: 0 },
                {
                    opacity: 0,
                    translateX: "150%",
                    translateY: "100px",
                    duration: 1,
                }
            );
        }

        if (number === 3 && lastPage === 2) {
            gsap.fromTo(
                "#mockup",
                { translateY: "-50%" },
                { translateY: "-250%", duration: 1.5, delay: 0.05 }
            );
            gsap.fromTo(
                "#italia",
                { opacity: 0, translateX: "150%", translateY: "100px" },
                {
                    opacity: 1,
                    translateX: 0,
                    translateY: 0,
                    duration: 1,
                    delay: 0.2,
                }
            );
        }

        if (number === 3) {
            if (playerRef.current) {
                playerRef.current.stop(); // Ferma l'animazione se è in corso
                playerRef.current.play(); // Avvia l'animazione
            }

            setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.pause(); // Ferma l'animazione dopo 8 secondi
                }
            }, 8000);
        } else {
            if (playerRef.current) {
                playerRef.current.pause();
            }
        }

        if (number === 5 && lastPage === 4) {
            gsap.fromTo(
                "#contatti_chisiamo",
                {
                    height: "100vh",
                },
                {
                    height: "80vh",
                    duration: 1,
                }
            );
            gsap.fromTo(
                "#footer_chisiamo",
                { translateY: "100%" },
                { translateY: 0, duration: 1 }
            );
        }
        if (number === 4 && lastPage === 5) {
            gsap.fromTo(
                "#contatti_chisiamo",
                {
                    height: "80vh",
                },
                {
                    height: "100vh",
                    duration: 1,
                }
            );
            gsap.fromTo(
                "#footer_chisiamo",
                { translateY: 0 },
                { translateY: "100%", duration: 1 }
            );
        }
        if (isMobile) {
            if (number === 4 && lastPage === 3) {
                gsap.fromTo(
                    "#italia",
                    { translateY: 0 },
                    { translateY: "-25%", duration: 1 }
                );
            }
            if (number === 3 && lastPage === 4) {
                gsap.fromTo(
                    "#italia",
                    { translateY: "-25%" },
                    { translateY: 0, duration: 1 }
                );
            }
            if (number === 5 && lastPage === 4) {
                gsap.fromTo(
                    "#italia",
                    { translateY: isMobile ? "-25%" : 0 },
                    { translateY: "-150%", duration: 1 }
                );
            }
            if (number === 4 && lastPage === 5) {
                gsap.fromTo(
                    "#italia",
                    { translateY: "-150%" },
                    { translateY: isMobile ? "-25%" : 0, duration: 1 }
                );
            }
        } else {
            if (number === 4 && lastPage === 3) {
                gsap.fromTo(
                    "#italia",
                    { translateY: isMobile ? "-25%" : 0 },
                    { translateY: "-150%", duration: 1 }
                );
            }
            if (number === 3 && lastPage === 4) {
                gsap.fromTo(
                    "#italia",
                    { translateY: "-150%" },
                    { translateY: isMobile ? "-25%" : 0, duration: 1 }
                );
            }
        }

        lastPage = number;
    };

    return (
        <div className="relative">
            <div
                id="sfumatura2"
                className="fixed top-[10%] opacity-0 -right-[50%] w-full h-[100lvh] z-0"
            >
                <img src={Sfumatura2} alt="" />
            </div>
            {!isMobile && (
                <div
                    id="mockup"
                    className={` translate-x-[650%] left-1/2 fixed top-[50%] -translate-y-1/2  w-[320px] pt-10 `}
                >
                    <div className="relative w-full">
                        <video
                            width="320"
                            autoPlay
                            loop
                            muted
                            className="rounded-[3rem] px-3 pt-4 "
                        >
                            <source src={videoFile} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <img
                            className="absolute top-3 -left-0 w-[320px]"
                            src={require("../../assets/phone_14_01-[Convertito].png")}
                            alt=""
                        />
                    </div>
                </div>
            )}

            <div
                id="italia"
                className="grid w-full lg:grid-cols-2 translate-x-[150%] text-bianco fixed lg:top-[25%] top-[15%] lg:opacity-0 grid-cols-1 col-span-2"
            >
                <div className=" lg:h-auto lg:w-auto relative ">
                    <h1 className="uppercase pl-5 block lg:hidden font-bold lg:text-6xl text-4xl">
                        <span className="font-light lg:text-5xl text-3xl">
                            il nostro
                        </span>{" "}
                        metodo
                    </h1>
                    <Player
                        key={`${lastPage}`}
                        ref={playerRef}
                        loop={false}
                        src={require("../../assets/Animazione sito_ITALY.json")}
                        className=" my-16 md:my-0 w-full md:h-[70lvh] h-[40lvh]"
                    ></Player>
                </div>
                <div className="h-[70lvh] flex justify-start flex-col items-start lg:pl-20 pl-5">
                    <h1 className="uppercase lg:block hidden font-semibold text-7xl">
                        <span className="font-light text-5xl">il nostro</span>{" "}
                        <br /> metodo
                    </h1>
                    <div className="">
                        <p className="lg:mt-10 font-thin lg:text-3xl text-2xl">
                            <span className="lg:text-6xl text-4xl font-semibold">
                                300+
                            </span>
                            <span className="font-semibold">Artisti</span>{" "}
                            operativi in tutta{" "}
                            <br className="lg:block hidden" /> Italia e più di{" "}
                            <span className="font-semibold">300+ progetti</span>{" "}
                            alle spalle
                        </p>
                    </div>

                    <div className=" lg:mt-20 mt-5 lg:pr-20 pr-5 flex justify-start">
                        <p className="rounded-lg lg:text-2xl lg:pr-20 text-xl font-thin bg-azzurro lg:px-5 px-3 lg:py-4 py-2">
                            <span className="font-bold">
                                Identificheremo la vostra necessità
                            </span>{" "}
                            e dopo che essa verrà elaborata dal nostro team
                            interno,{" "}
                            <span className="font-bold">
                                creeremo una squadra su misura
                            </span>{" "}
                            per le vostre esigenze attingendo dal nostro roster
                            di Artisti.
                        </p>
                    </div>
                </div>
            </div>
            {isMobile ? (
                <div className="">
                    <div className=" h-[100lvh] flex w-full relative z-10 justify-center pt-10 items-center">
                        <div className="w-[80vw] text-bianco z-10 lg:h-[80lvh] h-[70lvh] flex justify-center items-end bg-grigio brightness-150 rounded-xl relative">
                            <video
                                className="absolute top-0 left-0 w-full h-full object-cover rounded-xl"
                                src="https://chimerastudio.s3.eu-north-1.amazonaws.com/FELPA+LOOP+FINAL_1.mp4"
                                autoPlay
                                loop
                                muted
                            ></video>
                            <div className="text-center relative md:mb-20 mb-10 z-10 ">
                                <img
                                    className="absolute -top-[30%] left-1/2 -translate-x-1/2"
                                    src={require("../../assets/pittogramma_outline.png")}
                                    alt=""
                                />
                                <p className="text-xl md:text-2xl font-light">
                                    Chimera Studio
                                </p>
                                <p className="text-xl md:text-4xl font-bold uppercase tracking-wide">
                                    We rise by
                                </p>
                                <p className="text-xl md:text-4xl font-bold uppercase tracking-wide">
                                    lifting others
                                </p>
                            </div>
                        </div>
                        <div
                            id="scroll"
                            className=" absolute bottom-5 left-1/2 -translate-x-1/2 flex opacity-100 flex-col items-center z-10  mt-16  "
                        >
                            <div className="animate-scroll">
                                <ArrowUp />
                            </div>
                            <p className="uppercase text-bianco text-3xl">
                                scroll
                            </p>
                        </div>
                    </div>
                    <div
                        id="vision"
                        className=" section relative flex lg:gap-16 px-[10vw] lg:items-center  pt-32 text-bianco"
                    >
                        <div className="absolute top-0 left-0 w-[200vw]">
                            <img src={Sfumatura1} className="opacity-30 rotate-180 w-[2000vw]" alt="" />
                        </div>
                        <div id="vision_text" className=" w-[80vw] ">
                            <h1 className="lg:text-[64px] text-4xl font-bold">
                                LA NOSTRA{" "}
                            </h1>
                            <h1 className="lg:text-[64px] text-4xl font-bold lg:mt-5">
                                VISION{" "}
                            </h1>
                            <p className="lg:mt-12 font-light mt-6 lg:text-2xl text-xl">
                                Illuminare i talenti nascosti, nutrire la
                                creatività e connettere nuovi artisti con le
                                aziende in cerca di innovazione. Immaginiamo un
                                futuro senza barriere tra talento e opportunità,
                                dove ogni voce trova il suo spazio. Crediamo nel
                                potere trasformativo della creatività per
                                rendere il mondo vibrante e pieno di
                                significato. Questa è la nostra missione: far
                                fiorire il talento e guidare il progresso.
                            </p>
                        </div>
                    </div>
                    <div
                        id="vision2"
                        className=" section  flex l px-[10vw]  pt-12  text-bianco"
                    >
                        <div
                            id="vision_text2"
                            className=" text-bianco  w-[80vw] "
                        >
                            <h1 className="lg:text-6xl text-4xl font-bold">
                                COME NASCE CHIMERA
                            </h1>
                            <p className="lg:mt-12 mt-6 lg:text-2xl text-xl font-light">
                                Nasce dal sogno di Jeck Scardy, imprenditore
                                piemontese classe 2002, per dare spazio alle
                                nuove generazioni di artisti nel mondo della
                                comunicazione, dai video, al metaverso.
                            </p>
                        </div>
                    </div>
                    <div
                        id="italia"
                        className="grid w-full  text-bianco  grid-cols-1 mt-52"
                    >
                        <div
                            ref={containerRef}
                            className=" lg:h-auto lg:w-auto relative "
                        >
                            <h1 className="uppercase pl-5 block lg:hidden font-bold lg:text-6xl text-4xl">
                                <span className="font-light lg:text-5xl text-3xl">
                                    il nostro
                                </span>{" "}
                                metodo
                            </h1>
                            <Player
                                key={`${lastPage}`}
                                ref={mobile_playerRef}
                                loop={false}
                                src={require("../../assets/Animazione sito_ITALY.json")}
                                className=" my-16 lg:my-0 w-full lg:h-[70lvh] h-[50lvh]"
                            ></Player>
                        </div>
                        <div className=" flex justify-start flex-col items-start lg:pl-20 pl-5">
                            <h1 className="uppercase lg:block hidden font-semibold text-7xl">
                                <span className="font-light text-5xl">
                                    il nostro
                                </span>{" "}
                                <br /> metodo
                            </h1>
                            <div className="">
                                <p className="lg:mt-10 font-thin lg:text-3xl text-2xl">
                                    <span className="lg:text-6xl text-4xl font-semibold">
                                        300+
                                    </span>
                                    <span className="font-semibold">
                                        Artisti
                                    </span>{" "}
                                    operativi in tutta{" "}
                                    <br className="lg:block hidden" /> Italia e
                                    più di{" "}
                                    <span className="font-semibold">
                                        300+ progetti
                                    </span>{" "}
                                    alle spalle
                                </p>
                            </div>

                            <div className=" lg:mt-20 mt-5 lg:pr-20 pr-5 flex justify-start">
                                <p className="rounded-lg lg:text-2xl lg:pr-20 text-xl font-thin bg-azzurro lg:px-5 px-3 lg:py-4 py-2">
                                    <span className="font-bold">
                                        Identificheremo la vostra necessità
                                    </span>{" "}
                                    e dopo che essa verrà elaborata dal nostro
                                    team interno,{" "}
                                    <span className="font-bold">
                                        creeremo una squadra su misura
                                    </span>{" "}
                                    per le vostre esigenze attingendo dal nostro
                                    roster di Artisti.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className=" mt-52 mb-44 relative">
                        <div
                            id="contatti_chisiamo"
                            className="section w-full h-[100%] flex justify-center items-center relative"
                        >
                            <Contattaci />{" "}
                        </div>
                    </div>
                    <div id="footer_chisiamo" className="">
                        <Footer />
                    </div>
                </div>
            ) : (
                <ReactPageScroller
                    animationTimer={800}
                    animationTimerBuffer={300}
                    containerHeight={"100dvh"}
                    renderAllPagesOnFirstRender={true}
                    transitionTimingFunction={"ease-in-out"}
                    onBeforePageScroll={handlePageChange}
                >
                    <div className=" h-[100dvh] flex w-full relative z-10 justify-center pt-10 items-center">
                        <div
                            id="werise"
                            className="w-[80vw] text-bianco z-10 lg:h-[80vh] h-[70vh] flex justify-center items-end bg-grigio brightness-150 rounded-xl relative"
                        >
                            <video
                                className="absolute top-0 left-0 w-full h-full object-cover rounded-xl"
                                src="https://chimerastudio.s3.eu-north-1.amazonaws.com/FELPA+LOOP+FINAL_1.mp4"
                                autoPlay
                                loop
                                muted
                            ></video>
                            <div className="text-center relative md:mb-20 mb-10 z-10 ">
                                <img
                                    className="absolute -top-[30%] left-1/2 -translate-x-1/2"
                                    src={require("../../assets/pittogramma_outline.png")}
                                    alt=""
                                />
                                <p className="text-xl md:text-2xl font-light">
                                    Chimera Studio
                                </p>
                                <p className="text-xl md:text-4xl font-bold uppercase tracking-wide">
                                    We rise by
                                </p>
                                <p className="text-xl md:text-4xl font-bold uppercase tracking-wide">
                                    lifting others
                                </p>
                            </div>
                        </div>
                        <div
                            id="scroll"
                            className="hidden absolute bottom-10 left-1/2 -translate-x-1/2 flex opacity-100 flex-col items-center z-10  mt-10  "
                        >
                            <div className="animate-scroll">
                                <ArrowUp />
                            </div>
                            <p className="uppercase text-bianco text-3xl">
                                scroll
                            </p>
                        </div>
                    </div>
                    <div
                        id="vision"
                        className=" section h-[100dvh] relative flex lg:gap-16 px-[10vw] lg:items-center lg:pt-0 pt-32 text-bianco"
                    >
                        <div className="absolute right-0 ">
                            <img src={Sfumatura1} className="rotate-180 translate-x-1/2 opacity-30" alt="" />
                        </div>
                        <div
                            id="vision_text"
                            className="lg:pr-24 -translate-x-[150%]  lg:col-span-1 lg:w-[40vw] w-[80vw] col-span-2"
                        >
                            <h1 className="lg:text-[64px] text-4xl font-bold">
                                LA NOSTRA{" "}
                            </h1>
                            <h1 className="lg:text-[64px] text-4xl font-bold lg:mt-5">
                                VISION{" "}
                            </h1>
                            <p className="lg:mt-12 font-light mt-6 lg:text-2xl text-xl">
                                Illuminare i talenti nascosti, nutrire la
                                creatività e connettere nuovi artisti con le
                                aziende in cerca di innovazione. Immaginiamo un
                                futuro senza barriere tra talento e opportunità,
                                dove ogni voce trova il suo spazio. Crediamo nel
                                potere trasformativo della creatività per
                                rendere il mondo vibrante e pieno di
                                significato. Questa è la nostra missione: far
                                fiorire il talento e guidare il progresso.
                            </p>
                        </div>
                    </div>
                    <div
                        id="vision2"
                        className=" section relative h-[100dvh] flex lg:gap-16 px-[10vw] lg:pt-0 pt-12 lg:items-center text-bianco"
                    >
                         <div className="absolute left-0 bottom-0">
                            <img src={Sfumatura1} className=" -translate-x-1/2 translate-y-[100vh] opacity-30" alt="" />
                        </div>
                        <div
                            id="vision_text2"
                            className="lg:pr-24 lg:col-span-1 text-bianco lg:w-[40vw] w-[80vw] col-span-2"
                        >
                            <h1 className="lg:text-6xl text-4xl font-bold mt-28">
                                COME NASCE CHIMERA
                            </h1>
                            <p className="lg:mt-8 mt-8 lg:text-2xl text-xl font-light">
                                Nasce dal sogno di Jeck Scardy, imprenditore
                                piemontese classe 2002, per dare spazio alle
                                nuove generazioni di artisti nel mondo della
                                comunicazione, dai video, al metaverso.
                            </p>
                        </div>
                    </div>

                    <div className="relative h-[100dvh]"></div>
                    {isMobile && <div className="relative h-[100dvh]"></div>}

                    <SectionContainer height={1}></SectionContainer>
                    <div className="h-[100dvh] relative">
                        <div
                            id="contatti_chisiamo"
                            className="section w-full h-[100%] flex justify-center items-center relative"
                        >
                        <div className="absolute right-0">
                            <img src={Sfumatura2} className="opacity-30 translate-x-1/2 translate-y-1/2" alt="" />
                        </div>
                            <Contattaci />{" "}
                        </div>
                        <div
                            id="footer_chisiamo"
                            className="absolute w-full translate-y-[100%] bottom-0"
                        >
                            <Footer />
                        </div>
                    </div>
                </ReactPageScroller>
            )}
        </div>
    );
}

export default Chisiamo;
