import React from "react";

function TikTok() {
	return (
		<svg
			width="24"
			height="26"
			viewBox="0 0 24 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.5385 9.21966C12.5385 6.37589 12.5385 3.53212 12.5395 0.688346C12.5395 0.0257894 12.56 0.0083807 13.2195 0.00633261C14.3296 0.00428453 15.4407 0.0175971 16.5507 0.000188351C16.9573 -0.00595591 17.1047 0.13741 17.1344 0.5501C17.3484 3.51675 19.8727 5.96115 22.6079 6.09018C23.2091 6.11885 23.2869 6.2233 23.2879 6.83568C23.291 7.91298 23.292 8.98925 23.2879 10.0665C23.2859 10.6656 23.165 10.7977 22.5752 10.7352C20.8527 10.554 19.1989 10.1423 17.711 9.20327C17.5502 9.10189 17.3843 8.85817 17.1938 8.9831C17.0249 9.0937 17.1232 9.35995 17.1232 9.55554C17.118 12.214 17.1334 14.8724 17.116 17.5308C17.0976 20.2507 16.0459 22.4851 13.9097 24.1963C10.9738 26.5495 6.64617 26.5229 3.73686 24.1205C1.2341 22.055 0.273544 19.3843 0.751772 16.2139C1.36005 12.1832 5.489 9.07014 9.53807 9.51765C9.88522 9.55554 10.0521 9.69993 10.0501 10.0594C10.0429 11.2708 10.0429 12.4823 10.0501 13.6937C10.0521 14.047 9.92925 14.1586 9.56162 14.1074C6.69942 13.7101 4.6585 15.9067 5.34154 18.7084C5.77573 20.4893 7.82484 22.0479 10.2324 21.074C11.7817 20.4473 12.5262 19.2092 12.5334 17.5482C12.5457 14.772 12.5365 11.9958 12.5375 9.21966H12.5385Z"
				fill="#DFDFDF"
			/>
		</svg>
	);
}

export default TikTok;
