import React, { useState } from "react";
import { motion } from "framer-motion";

function ServizioLargo({ titolo, descr1, descr2, backgroundImage, redirect }) {
    const [hover, setHover] = useState(window.innerWidth > 1024 ? false : true);

    return (
        <div
            onClick={() => (window.location.href = "/servizi/" + redirect)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => window.innerWidth > 1024 && setHover(false)}
            style={{ backgroundImage: `url(${backgroundImage})` }}
            className={`cursor-pointer bg-azzurro rounded-lg aspect-auto w-full h-80 xl:h-[35vh]  hover:scale-[102%] duration-300 transition-all bg-cover bg-center bg-no-repeat overflow-hidden`}
        >
            <div className="h-full relative  rounded-lg p-6">
                <motion.div
                    initial={{ opacity: 0, x: "-10%", y: 0 }}
                    animate={{ opacity: 1, x: 0, y: 0 }}
                    transition={{
                        duration: 0.3,
                        ease: [0.54, 0.44, 0.43, 0.81],
                    }}
                    exit={{ opacity: 0 }}
                    className={`${"absolute"} top-0 left-0 z-0 h-full w-[100%] bg-gradient-to-b from-[#000] via-nero/0 to-nero/0`}
                ></motion.div>
                <motion.div
                    initial={{ opacity: 0, x: "-10%", y: 0 }}
                    animate={
                        hover
                            ? { opacity: 1, x: 0, y: 0 }
                            : { opacity: 0, x: "-10%", y: 0 }
                    }
                    transition={{
                        duration: 0.3,
                        ease: [0.54, 0.44, 0.43, 0.81],
                    }}
                    exit={{ opacity: 0 }}
                    className={`${
                        !hover ? "hidden" : "absolute"
                    } top-0 left-0 z-0 h-full w-[100%] bg-gradient-to-r from-[#000] via-nero/80`}
                ></motion.div>
                <h1 className="z-20 absolute uppercase text-xl font-bold">
                    {titolo}
                </h1>
                <motion.p
                    className={`${
                        !hover ? "hidden" : "absolute"
                    } top-[20%] text-lg lg:w-[50%] `}
                    initial={{ opacity: 0, x: "-10%", y: 0 }}
                    animate={
                        hover
                            ? { opacity: 1, x: 0, y: 0 }
                            : { opacity: 0, x: "-10%", y: 0 }
                    }
                    transition={{
                        duration: 0.3,
                        ease: [0.54, 0.44, 0.43, 0.81],
                    }}
                    exit={{ opacity: 0 }}
                >
                    {descr1}
                </motion.p>
                <motion.p
                    className={`${
                        !hover ? "hidden" : "absolute"
                    }  bottom-[16%] text-lg lg:w-[50%]`}
                    initial={{ opacity: 0, x: "-10%", y: 0 }}
                    animate={
                        hover
                            ? { opacity: 1, x: 0, y: 0 }
                            : { opacity: 0, x: "-10%", y: 0 }
                    }
                    transition={{
                        duration: 0.3,
                        ease: [0.54, 0.44, 0.43, 0.81],
                    }}
                    exit={{ opacity: 0 }}
                >
                    {descr2}
                </motion.p>
            </div>
        </div>
    );
}

export default ServizioLargo;
