import React from 'react';

const Condizioni = () => {
  return (
    <div className="text-lg text-bianco p-24 mt-10">
      <h1 className="text-3xl text-center font-bold">CONDIZIONI GENERALI D'USO</h1>

      <h2 className="text-xl text-center my-20">Art. 1 - Oggetto</h2>
      <p className="mt-5">
        1. Le presenti condizioni generali d'uso rappresentano l'accesso e l'uso del sito
        Chimera Studios, accessibile mediante l'url: <a className='underline' href="https://chimerastudioss.com">https://chimerastudioss.com</a>, (d'ora in
        avanti il "titolare"), sono attività regolate dalle presenti condizioni generali d'uso.
      </p>
      <p className="mt-5">
        2. Il presente sito è di proprietà di:
        <br />
        Società Chimera Studio S.R.L.
        <br />
        Sede: Via Frua 22, 20146 Milano (MI)
        <br />
        Partita IVA: 12743600962
        <br />
        Iscritta al REA, numero 2681155
      </p>
      <p className="mt-5">
        3. L'accesso al sito e il suo uso, così come l'acquisto di prodotti in esso presentati,
        presuppone la lettura, la conoscenza e l'accettazione di queste condizioni generali
        d'uso.
      </p>

      <h2 className="text-xl text-center my-20">Art. 2 - Modifiche alle condizioni d'uso</h2>
      <p className="mt-5">
        1. Il titolare potrà modificare o semplicemente aggiornare, in tutto o in parte, queste
        condizioni generali d'uso. Le modifiche e gli aggiornamenti delle condizioni generali
        d'uso saranno notificati agli utenti nella Home page non appena adottati e saranno
        vincolanti non appena pubblicati sul sito web in questa stessa sezione. L'accesso e
        l'utilizzo del sito presuppongono l'accettazione da parte dell'utente delle presenti
        condizioni d'uso.
      </p>

      <h2 className="text-xl text-center my-20">Art. 3 - Proprietà intellettuale</h2>
      <p className="mt-5">
        1. I contenuti presenti sul sito, quali, a titolo esemplificativo, le opere, le immagini, le
        fotografie, i dialoghi, le musiche, i suoni ed i video, i documenti, i disegni, le figure, i
        loghi ed ogni altro materiale, in qualsiasi formato, pubblicato sul sito medesimo,
        compresi i menu, le pagine web, la grafica, i colori, gli schemi, gli strumenti, i caratteri
        ed il design del sito, i diagrammi, il layouts, i metodi, i processi, le funzioni ed il
        software che fanno parte del sito, sono protetti dal diritto d'autore e da ogni altro
        diritto di proprietà intellettuale del titolare o di eventuali terzi dallo stesso
        contrattualizzati. È vietata la riproduzione, in tutto o in parte, in qualsiasi forma, del
        sito e dei suoi contenuti, senza il consenso espresso in forma scritta del titolare.
      </p>
      <p className="mt-5">
        2. L'utente è autorizzato unicamente a visualizzare il sito ed i suoi contenuti fruendo
        dei relativi servizi ivi disponibili. L'utente è, inoltre, autorizzato a compiere tutti quegli
        altri atti di riproduzione temporanei, privi di rilievo economico proprio, che sono
        considerati transitori o accessori, parte integrante ed essenziale della stessa
        visualizzazione e fruizione del Sito e dei suoi contenuti e tutte le altre operazioni di
        navigazione sul Sito che siano eseguite solo per un uso legittimo del medesimo.
      </p>
      <p className="mt-5">
        3. L'utente non è in alcun modo autorizzato ad eseguire alcuna riproduzione, su
        qualsiasi supporto, in tutto o in parte del sito e dei suoi contenuti. Qualsiasi atto di
        riproduzione dovrà essere, di volta in volta, autorizzato da Chimera Studios o,
        all'occorrenza, dagli autori delle singole opere contenute nel sito. Tali operazioni di
        riproduzione dovranno essere comunque eseguite per scopi leciti e nel rispetto del
        diritto d'autore e degli altri diritti di proprietà intellettuale e degli autori delle singole
        opere contenute nel sito.
      </p>

      <h2 className="text-xl text-center my-20">Art. 4 - Utilizzo del sito e responsabilità dell'utente</h2>
      <p className="mt-5">
        1. L'accesso e l'uso del sito, la visualizzazione delle pagine web, compresa la
        comunicazione con il titolare, la possibilità di scaricare informazioni sui prodotti e
        l'acquisto degli stessi sul sito web, costituiscono attività condotte dall'utente
        esclusivamente per usi personali estranei a qualsiasi attività commerciale,
        imprenditoriale e professionale.
      </p>
      <p className="mt-5">
        2. L'utente è personalmente responsabile per l'uso del sito e dei relativi contenuti. Il
        titolare infatti non potrà essere considerato responsabile dell'uso non conforme alle
        norme di legge vigenti, del sito web e dei contenuti da parte di ciascuno dei propri
        utenti, salva la responsabilità per dolo e colpa grave. In particolare, l'utente sarà
        l'unico ed il solo unico responsabile per la comunicazione di informazioni e di dati
        non corretti, falsi o relativi a terzi soggetti, senza che questi abbiano espresso il loro
        consenso, nonché in considerazione di un uso non corretto degli stessi.
      </p>
      <p className="mt-5">
        3. Ogni materiale scaricato o altrimenti ottenuto attraverso l'uso del servizio è a
        scelta e a rischio dell'utente, pertanto ogni responsabilità per eventuali danni a
        sistemi di computer o perdite di dati risultanti dalle operazioni di scarico ricade
        sull'utente e non potrà essere imputata al titolare.
      </p>
      <p className="mt-5">
        4. Il titolare declina ogni responsabilità per eventuali danni derivanti
        dall'inaccessibilità ai servizi presenti sul sito o da eventuali danni causati da virus, file
        danneggiati, errori, omissioni, interruzioni del servizio, cancellazioni dei contenuti,
        problemi connessi alla rete, ai provider o a collegamenti telefonici e/o telematici, ad
        accessi non autorizzati, ad alterazioni di dati, al mancato e/o difettoso funzionamento
        delle apparecchiature elettroniche dell'utente stesso.
      </p>
      <p className="mt-5">
        5. L'utente è responsabile della custodia e del corretto utilizzo delle proprie
        informazioni personali, ivi comprese le credenziali che consentono di accedere ai
        servizi riservati, nonché di ogni conseguenza dannosa o pregiudizio che dovesse
        derivare a carico di Chimera Studios ovvero di terzi a seguito del non corretto utilizzo,
        dello smarrimento, sottrazione di tali informazioni.
      </p>

      <h2 className="text-xl text-center my-20">Art. 5 - Esclusione di responsabilità</h2>
      <p className="mt-5">
        1. Come indicato in precedenza, il titolare svolge con la massima diligenza la cura e il
        mantenimento del sito e dei suoi contenuti, tuttavia, non si assume alcuna
        responsabilità per la correttezza, la completezza e la tempestività dei dati e delle
        informazioni fornite sul sito o sui siti ad esso collegati. Deve perciò escludersi ogni
        responsabilità per errori od omissioni derivanti dall'uso dei dati e delle informazioni
        sul sito.
      </p>
      <p className="mt-5">
        2. Il titolare declina ogni responsabilità, inclusa la presenza di errori, la correzione
        degli errori, la responsabilità del server ospitante il sito; non è altresì responsabile
        dell'uso delle informazioni contenute, della loro correttezza e affidabilità. In nessun
        caso, inclusa la negligenza, il titolare sarà responsabile di ogni diretto o indiretto
        danno che possa risultare dall'uso, o dalla incapacità di usare, i materiali presenti nel
        sito.
      </p>

      <h2 className="text-xl text-center my-20">Art. 6 - Limitazioni all'erogazione del servizio</h2>
      <p className="mt-5">
        1. Il titolare non potrà essere ritenuto responsabile dei danni conseguenti alla
        mancata prestazione del servizio a causa dell'errato o mancato funzionamento dei
        mezzi elettronici di comunicazione per cause estranee alla sfera del proprio
        prevedibile controllo. A titolo esemplificativo, ma non esaustivo, il malfunzionamento
        dei server ed altri dispositivi elettronici anche non facenti parte integrante della rete
        Internet, malfunzionamento dei software installati, virus informatici sull'eventuale
        presenza di virus o altri componenti informatici nocivi e dannosi, nonché da azioni di
        hacker o altri utenti aventi accesso alla rete. L'utente s'impegna dunque a tenere
        indenne e manlevare il titolare da qualsiasi responsabilità e/o richiesta al riguardo.
      </p>

      <h2 className="text-xl text-center my-20">Art. 7 - Link di altri siti</h2>
      <p className="mt-5">
        1. Il sito può contenere collegamenti ipertestuali ad altri siti web che non hanno
        nessun collegamento con lo stesso. Il titolare non controlla né monitora di tali siti web
        e non ne garantisce pertanto in alcun modo i contenuti né la gestione dei dati.
        L'utente dovrà pertanto leggere attentamente le condizioni d'uso dei siti terzi visitati e
        le relative privacy policy, in quanto le presenti condizioni d'uso e la privacy policy si
        riferiscono unicamente al presente sito.
      </p>

      <h2 className="text-xl text-center my-20">Art. 8 - Link in altre pagine web</h2>
      <p className="mt-5">
        1. Il presente sito può essere raggiunto anche attraverso siti terzi dove sarà presente
        un link o banner per accedere al sito.
      </p>
      <p className="mt-5">
        2. L'attivazione di link su siti terzi verso il presente sito è possibile sempre e quando
        non sia lesivo delle presente condizioni d'uso.
      </p>
      <p className="mt-5">
        3. L'attivazione di link non autorizzati legittimerà il titolare ad agire per l'immediata
        disattivazione dei link illegittimi e per l'eventuale riconoscimento della relativa pratica
        commerciale o concorrenza sleale ovvero azione a discapito del buon nome e della
        rinomanza del titolare, dei suoi servizi e delle società del medesimo gruppo. È in ogni
        caso vietata l'attivazione di collegamenti ipertestuali profondi (quali deep frames o
        deep links) al Sito ovvero l'uso non autorizzato di meta-tags.
      </p>

      <h2 className="text-xl text-center my-20">Art. 9 - Marchi</h2>
      <p className="mt-5">
        1. Tutti i marchi ed i segni distintivi presenti sul sito, anche relativi alle singole attività
        svolte dal titolare, sono esclusiva del titolare medesimo o delle società a lui referenti.
      </p>
      <p className="mt-5">
        2. Il titolare ha la facoltà di far uso esclusivo dei suddetti marchi. Pertanto, qualsiasi
        uso non consentito, non autorizzato e/o non conforme alla legge è severamente
        vietato e comporta conseguenze legali. Non è in alcun modo consentito usare detti
        marchi ed ogni altro segno distintivo presente sul sito per trarre indebitamente, anche
        indirettamente, vantaggio dal carattere distintivo o dalla rinomanza dei marchi del
        titolare o in modo da recare pregiudizio agli stessi ed ai loro titolari.
      </p>
      <p className="mt-5">
        3. Il dominio <a className='underline' href="https://chimerastudioss.com">https://chimerastudioss.com</a> così come le varie declinazioni ed i
        sottodomini sono di proprietà del titolare. Nessun utilizzo, seppur indiretto, è
        consentito, salva specifica autorizzazione scritta del titolare o dei titolari.
      </p>

      <h2 className="text-xl text-center my-20">Art. 10 - Trattamento dei dati</h2>
      <p className="mt-5">
        1. I dati dell'utente sono trattati conformemente a quanto previsto dalla normativa in
        materia di protezione dei dati personali, come specificato nell'apposita sezione
        contenente l'informativa ai sensi dell'art. 13 Regolamento UE 2016/679 (Privacy
        Policy). Per più informazioni accedere alla privacy policy del sito al link: <a className='underline' href="https://chimerastudioss.com/privacy-policy">https://chimerastudioss.com/privacy-policy</a>
      </p>

      <h2 className="text-xl text-center my-20">Art. 11 - Clausola di salvaguardia</h2>
      <p className="mt-5">
        1. Nell'ipotesi in cui una delle clausole delle presenti condizioni generali d'uso fosse
        nulla per qualsivoglia motivo ciò non comprometterà in nessun caso la validità e il
        rispetto delle altre disposizioni figuranti nelle presenti condizioni generali d'uso.
      </p>

      <h2 className="text-xl text-center my-20">Art. 12 - Contatti</h2>
      <p className="mt-5">
        1. Ogni richiesta di informazione potrà essere inviata via mail al seguente indirizzo
        jeck@chimerastudioss.com, via telefono al seguente recapito telefonico:
        +393517649360, e via posta al seguente indirizzo:
        <br />
        Via Frua 22, 20146 Milano (MI).
      </p>

      <h2 className="text-xl text-center my-20">Art. 13 - Legge applicabile e foro competente</h2>
      <p className="mt-5">
        1. Le presenti condizioni generali d'uso sono disciplinate dal diritto italiano ed
        interpretate in base ad esso, fatta salva qualsiasi diversa norma imperativa
        prevalente del paese di residenza abituale del l'acquirente. Di conseguenza
        l'interpretazione, esecuzione e risoluzione delle condizioni generali d'uso sono
        soggette esclusivamente alla legge Italiana.
      </p>
      <p className="mt-5">
        2. Eventuali controversie inerenti e/o conseguenti alle stesse dovranno essere risolte
        in via esclusiva dall'autorità giurisdizionale Italiana. In particolare, qualora il
        l'acquirente rivesta la qualifica di consumatore, le eventuali controversie dovranno
        essere risolte dal tribunale del luogo di domicilio o residenza dello stesso in base alla
        legge applicabile.
      </p>
      <p className="mt-5">
        Le presenti condizioni sono state redatte in data 17/09/2024.
      </p>
    </div>
  );
};

export default Condizioni;