import React, { useState } from "react";
import JoinModal from "../../Components/Molecules/Modals/JoinModal";
import Sfumatura1 from "../../assets/sfumature/1.png";

function Join_us() {
	const [isOpenJ, setIsOpenJ] = useState(false);
	const [backJ, setBackJ] = useState(1);
	const [done, setDone] = useState(0);
	const [fail, setFail] = useState(0)
	return (
		<div className="h-[100vh] w-full">
			<div
                id="sfumatura1"
                className="hidden w-full fixed brightness-0 invert right-0 top-[80%] z-0 -translate-y-1/2 -translate-x-0 scale-[200%] rotate-[200deg]"
            >
				<img src={Sfumatura1} className=" opacity-15" alt="" />
			</div>
			<JoinModal
				isOpen={true}
				setIsOpen={setIsOpenJ}
				back={backJ}
				setBack={setBackJ}
				done={done}
				setDone={setDone}
				fail={fail}
				setFail={setFail}
			/>
		</div>
	);
}

export default Join_us;
