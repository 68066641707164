import logo_aia from './logo_aia.png';
import logo_assolombarda from './logo_assolombarda.png';
import logo_bea_bongiasca from './logo_bea bongiasca.webp';
import logo_bimbostore from './logo_bimbostore.png';
import logo_blanc from './logo_blanc.png';
import logo_blank from './logo_blank.png';
import logo_burberry from './logo_burberry.png';
import logo_canali from './logo_canali.png';
import logo_champion from './logo_champion.png';
import logo_chanel from './logo_chanel.png';
import logo_coccinelle from './logo_coccinelle.png';
import logo_colmar from './logo_colmar.png';
import logo_crazy_pizza from './logo_crazy pizza.png';
import logo_deloitte from './logo_deloitte.png';


 const images = [
    logo_aia,
    logo_assolombarda,
    logo_bea_bongiasca,
    logo_bimbostore,
    logo_blanc,
    logo_blank,
    logo_burberry,
    logo_canali,
    logo_champion,
    logo_chanel,
    logo_coccinelle,
    logo_colmar,
    logo_crazy_pizza,
    logo_deloitte,
    
];
export default images;