import React from 'react'

function LastProject() {
  return (
    <div className="flex space-x-5">
    <div className="w-56 md:h-56 bg-azzurro rounded-lg"></div>
    <div className="flex flex-col h-full justify-around">
        <p className="text-2xl uppercase text-azzurro font-bold">
            servizio
        </p>
        <p className="md:text-2xl font-bold">
            Beve Descrizione progetto
        </p>
        <p className="text-bianco font-bold md:text-2xl text-lg">
            GG/MM/AAAA
        </p>
    </div>
</div>
  )
}

export default LastProject