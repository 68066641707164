import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

function NFND() {
	return (
		<div className="px-12 text-center w-full h-[80vh] grid place-content-center text-bianco text-4xl">
			<h1>PAGINA NON TROVATA...</h1>
			<Player
				autoplay
				loop
				src={require("../../assets/Polite Chicky.json")}
				className="player max-w-96"
			></Player>
		</div>
	);
}

export default NFND;
