import React from "react";

function Team({ img, name, surname, role }) {
	return (
		<div className="flex gap-5 no-select">
			<img className="md:w-44 md:h-44 h-32 w-32 rounded-full" src={img } alt="" />
			<div className="h-full flex flex-col justify-center">
				<h1 className="md:text-4xl text-2xl uppercase font-semibold">{name}</h1>
				<h2 className="md:text-2xl text-xl font-semibold uppercase ">{surname}</h2>
				<h3 className=" md:text-lg italic capitalize ">{role}</h3>
			</div>
		</div>
	);
}

export default Team;
