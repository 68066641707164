import logo_PS from "./logo_P&S.png";
import logo_panerai from "./logo_panerai.png";
import logo_penny from "./logo_penny.png";
import logo_philips from "./logo_philips.png";
import logo_ps from "./logo_ps.png";
import logo_santa_lucia from "./logo_santa lucia.webp";
import logo_santa_margherita from "./logo_santa margherita.png";
import logo_sport_specialist from "./logo_sport specialist.png";
import logo_spotify from "./logo_spotify.png";
import logo_SUN68 from "./logo_SUN68.png";
import logo_tamron from "./logo_tamron.png";
import logo_technogym from "./logo_technogym.png";
import logo_Unopiu from "./logo_Unopiu.webp";
import logo_Veuve_Clicquot from "./logo_Veuve-Clicquot.png";

export default [
	logo_PS,
	logo_panerai,
	logo_penny,
	logo_philips,
	logo_ps,
	logo_technogym,
	logo_santa_margherita,
	logo_sport_specialist,
	logo_spotify,
	logo_SUN68,
	logo_tamron,
	logo_santa_lucia,
	logo_Unopiu,
	logo_Veuve_Clicquot,
];
